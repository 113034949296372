import {
    Box,
    InputAdornment,
    TextField,
    FormControl,
    useTheme,
    styled,
    Paper,
    Checkbox,
    Dialog,
    Slide,
    DialogTitle,
    DialogActions,
    CircularProgress,
} from '@mui/material'

import { connect } from 'react-redux'
import React, { ReactElement, useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import { Upload } from 'antd'
import { tokens } from '../theme'
import { TransitionProps } from '@mui/material/transitions'
import { AuthToken } from '../actions/auth'
import FileDetailsModal from './FileDetailsModal'
import { CustomUploadFile } from '../interfaces/CustomUploadFileType'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { UploadChangeParam } from 'antd/es/upload/interface'
import { useAuthQueryWithQueryFunction } from '../extensions/UseAuthQuery'
import { ApiError } from '../interfaces/ErrorType'
import {
    deleteFileUpload,
    loadCategories,
    loadImages,
    updateFile,
    uploadFile,
} from '../actions/content'
import AddCategoryModal from './AddCategoryModal'
import { useMutation } from 'react-query'
import AlertDialog from './AlertDialog'
import AutocompleteExt from './Autocomplete'
import ButtonExt from './ButtonExt'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

const Item = styled(Paper)(({ theme }) => ({
    // ...theme.typography.body2,
    // padding: theme.spacing(1),
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
}))

const MediaLibrary = (props: {
    user: AuthToken
    mediaType?: string
    onChangeEvent?: (mediaType: string, key: string, promptUsed?: string) => void
    onClose?: any
    disabledAssign?: boolean
}) => {
    const { onChangeEvent, onClose, user, mediaType, disabledAssign } = props
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const [fileList, setFileList] = useState<CustomUploadFile[]>([])
    const [selectedFiles, setSelectedFiles] = useState<CustomUploadFile[]>([])
    const [fileToRemove, setFileToRemove] = useState<CustomUploadFile | null>(
        null
    )
    const [detailFileSelected, setDetailFileSelected] =
        useState<CustomUploadFile | null>(null)
    const [deletePopupOpen, setDeletePopupOpen] = React.useState(false)

    const [fileDetailsOpen, setFileDetailsOpen] = useState(false)
    const [catCreateOpen, setCatCreateOpen] = useState(false)

    const [searchQuery, setSearchQuery] = useState('')
    const [mediaCategories, setMediaCategories] = useState<any>([])
    const [filteredFileList, setFilteredFileList] =
        useState<CustomUploadFile[]>(fileList)

    const [selectedCategory, setSelectedCategory] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [isSearching, setIsSearching] = useState(false)
    const [isLastPage, setIsLastPage] = useState(false)
    const [deleteError, setDeleteError] = useState<string>()
    const [showDeletionAlert, setShowDeletionAlert] = useState({
        enable: false,
        message: '',
    })

    const fileTypes = {
        IMAGE: '.png,.jpg,.jpeg,.svg,.webp',
        BANNER: '.png,.jpg,.jpeg,.svg,.webp',
        VIDEO: '.mp4,.mov,.wmv,.avi,.flv,.webm',
        AUDIO: '.mp3,.wav,.aac,.ogg',
    }

    let allowedFiles: string = ''

    if (mediaType === 'MEDIA') {
        allowedFiles = fileTypes.IMAGE
    } else if (mediaType === 'BANNER') {
        allowedFiles = fileTypes.BANNER
    } else if (mediaType === 'VIDEO') {
        allowedFiles = fileTypes.VIDEO
    } else if (mediaType === 'AUDIO') {
        allowedFiles = fileTypes.AUDIO
    }

    useEffect(() => {
        setFilteredFileList(fileList)
    }, [fileList])

    const categoriesResponse = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
    >(`${mediaType}-loadCategories`, loadCategories, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    useEffect(() => {
        if (categoriesResponse?.data) {
            if (mediaType === 'MEDIA' || mediaType === 'BANNER') {
                let cats = categoriesResponse?.data?.filter((cat: any) =>
                    cat.name.toLowerCase().includes('image')
                )
                setMediaCategories(cats)
                setSelectedCategory(cats[0]?.name)
            } else if (mediaType === 'VIDEO') {
                let cats = categoriesResponse?.data?.filter((cat: any) =>
                    cat.name.toLowerCase().includes('video')
                )
                setMediaCategories(cats)
                setSelectedCategory(cats[0]?.name)
            } else if (mediaType === 'AUDIO') {
                let cats = categoriesResponse?.data?.filter((cat: any) =>
                    cat.name.toLowerCase().includes('audio')
                )
                setMediaCategories(cats)
                setSelectedCategory(cats[0]?.name)
            }
        }
    }, [categoriesResponse?.data])

    const compileImageData = (data: any) => {
        const fetchedFileList = data?.map((image: any) => ({
            fileStorageCategoryId: image?.fileStorageCategoryId,
            height: image?.height,
            key: image?.key,
            categoryId: image?.fileStorageCategoryId,
            metadata:
                typeof image?.metadata === 'object'
                    ? Object.keys(image?.metadata)
                          .map((key) => image?.metadata[key])
                          .join('. ')
                    : image?.metadata.toString(),
            mime: image?.mime,
            payload: image?.payload,
            uid: image?.key,
            name: image?.name,
            customPromptUsed: image?.customPromptUsed,
            successful: image?.successful,
            status: image?.statusCode,
            thumbUrl: image?.url,
            createdAt: image?.createdAt,
            updatedAt: image?.updatedAt,
            createdBy: image?.createdBy,
            updatedBy: image?.updatedBy,
        }))
        return fetchedFileList
    }

    let catId = mediaCategories.find(
        (cat: any) => cat.name === selectedCategory
    )

    const imagesResponse: any = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
    >(`${mediaType}=loadImages`, () => loadImages(catId?.id, currentPage, searchQuery,mediaType === 'VIDEO' || mediaType === 'AUDIO' ? 10 : 25), {
        refetchOnWindowFocus: false,
        enabled: catId?.id !== undefined,
    })

    const fetchNextPage = () => {
        const nextPage = currentPage + 1
        if (!isLastPage) {
            setIsLoadingMore(true)
            let catId = mediaCategories.find(
                (cat: any) =>
                    cat.name.toLowerCase() === selectedCategory.toLowerCase()
            )
            loadImages(catId?.id, nextPage, searchQuery, mediaType === 'VIDEO' || mediaType === 'AUDIO' ? 10 : 25)
                .then((response) => {
                    const fetchedFiles = compileImageData(response.content)
                    setFileList((prev) => [...prev, ...fetchedFiles])
                    setCurrentPage(nextPage)
                    setIsLastPage(response.last)
                })
                .catch((error) =>
                    console.error('Error fetching next page:', error)
                )
                .finally(() => {
                    setIsLoadingMore(false)
                })
        }
    }

    useEffect(() => {
        if (imagesResponse?.data) {
            setIsLastPage(imagesResponse?.data?.last)
            const fetchedFileList = compileImageData(
                imagesResponse?.data?.content
            )
            setFileList(fetchedFileList)
        }
    }, [imagesResponse?.data])

    const handleFileDetailsOpen = () => {
        setFileDetailsOpen(true)
    }

    const handleFileDetailsClose = () => {
        setFileDetailsOpen(false)
    }

    const handleDeletePopupClose = () => {
        setDeletePopupOpen(false)
        setFileToRemove(null)
    }

    const onConfirmDelete = () => {
        setDeleteError('')
        if (fileToRemove) {
            handleRemove(fileToRemove)
        } else if (selectedFiles.length > 0) {
            handleBulkRemove()
        }
        handleDeletePopupClose()
    }

    const uploadFileMutation = useMutation<any, ApiError, any>(uploadFile)

    const updateFileData = useMutation<any, ApiError, any>(updateFile)

    const deleteFileMutation = useMutation<any, ApiError, any>(deleteFileUpload)

    const handleChange = async (info: UploadChangeParam<any>) => {
        const { file, fileList } = info
        if (mediaType === 'MEDIA' || mediaType === 'BANNER') {
            file['category'] = mediaCategories[0]?.name
        } else if (mediaType === 'VIDEO') {
            file['category'] = mediaCategories[1]?.name
        } else if (mediaType === 'AUDIO') {
            file['category'] = mediaCategories[2]?.name
        }
        if (file) {
            try {
                await uploadFileMutation.mutate(file, {
                    onSuccess: (data) => {
                        console.log('File Upload successful:', data.key)

                        let categoryId = ''
                        if (mediaType === 'MEDIA' || mediaType === 'BANNER') {
                            categoryId = mediaCategories?.find(
                                (each: any) =>
                                    each.name.toLowerCase() ===
                                    selectedCategory.toLowerCase()
                            )?.id
                        } else if (mediaType === 'VIDEO') {
                            categoryId = mediaCategories?.find(
                                (each: any) =>
                                    each.name.toLowerCase() ===
                                    selectedCategory.toLowerCase()
                            )?.id
                        } else if (mediaType === 'AUDIO') {
                            categoryId = mediaCategories?.find(
                                (each: any) =>
                                    each.name.toLowerCase() ===
                                    selectedCategory.toLowerCase()
                            )?.id
                        }

                        updateFileData.mutate(
                            {
                                data: {
                                    fileStorageCategoryId: categoryId,
                                    name: '',
                                },
                                key: data.key,
                            },
                            {
                                onSuccess: (data) => {
                                    console.log('File updated:', data)
                                    const cloneFileList = [...fileList]
                                    // Replace the last one with updated data
                                    cloneFileList[cloneFileList.length - 1] = {
                                        ...cloneFileList[
                                            cloneFileList.length - 1
                                        ],
                                        uid: data.key,
                                        categoryId: categoryId,
                                        category: selectedCategory,
                                        url: data.url,
                                        status: 200,
                                        successful: true,
                                        percent: 100,
                                    }
                                    setFileList(cloneFileList)
                                },
                            }
                        )
                    },
                })
            } catch (error) {
                console.error('Error uploading file:', error)
            }
        }
    }

    const onRemoveClick = (file: CustomUploadFile) => {
        setFileToRemove(file)
        setDeletePopupOpen(true)
    }

    const onRemoveBulkClick = () => {
        setDeletePopupOpen(true)
        return false
    }

    const handleRemove = (file: CustomUploadFile) => {
        deleteFileMutation.mutate(file.uid, {
            onSuccess: () => {
                console.log('File deleted:', file.uid)
                // Remove the file from selectedFiles if this file exists there.
                const filteredSelectedFiles = selectedFiles.filter(
                    (f: CustomUploadFile) => {
                        return f !== file
                    }
                )

                const filteredFiles = fileList.filter((f: CustomUploadFile) => {
                    return f !== file
                })

                setFileList(filteredFiles)
                setSelectedFiles(filteredSelectedFiles)
            },
            // Handle potential errors
            onError: (error) => {
                console.error('Error deleting file:', file.uid, error);

                // Handle specific error statuses (adjust based on your API):
                let errorMessage = `Error deleting file ${file.uid}`;

                if (error.message.includes('404')) {
                    errorMessage = `File storage with key ${file.uid} not found`;
                }

                if (error.message.includes('422')) {
                    errorMessage = `The file is currently in use.`;
                }

                // Update deletion error state immutably
                setDeleteError(errorMessage);

                // Update deletion alert state immutably
                setShowDeletionAlert({
                    enable: true,
                    message: errorMessage
                });
            },
        })
    }

    const handleBulkRemove = async () => {
        let immutatableDeleteError: any[] = []
        selectedFiles.forEach((file: CustomUploadFile) => {
            deleteFileMutation.mutate(file.uid, {
                onSuccess: () => {
                    console.log('File deleted:', file.uid)

                    const filteredFiles = fileList.filter((file: CustomUploadFile) => {
                        return !selectedFiles.includes(file)
                    })

                    setFileList(filteredFiles)
                    setSelectedFiles([])
                },
                onError: (error) => {
                    console.error('Error deleting file:', file.uid, error);

                    // Handle specific error statuses (adjust based on your API):
                    let errorMessage = `Error deleting file ${file.uid}`;

                    if (error.message.includes('404')) {
                        errorMessage = `The file with key ${file.uid} cannot be found.`;
                    }

                    if (error.message.includes('422')) {
                        errorMessage = `The file is currently in use.`;
                    }

                    // Update deletion error state immutably
                    immutatableDeleteError = [
                        ...immutatableDeleteError,
                        errorMessage
                    ];

                    // Update deletion error state immutably
                    setDeleteError(errorMessage);

                    // Update deletion alert state immutably
                    setShowDeletionAlert({
                        enable: true,
                        message: errorMessage
                    });
                }
            })
        })
    }

    const handleSelectFiles = (e: any, file: CustomUploadFile) => {
        if (e.target.checked) {
            setSelectedFiles([...selectedFiles, file])
        } else {
            const filterSelectedFiles = selectedFiles.filter(
                (f: CustomUploadFile) => {
                    return f !== file
                }
            )
            setSelectedFiles(filterSelectedFiles)
        }
    }

    const handleAssignMedia = () => {
        if (mediaType && selectedFiles.length === 1) {
            const selectedFile = selectedFiles[0]

            if (onChangeEvent && mediaType && selectedFile.uid) {
                onChangeEvent(mediaType, selectedFile.uid, selectedFile.customPromptUsed)
            }

            onClose('')
        }
    }

    const downloadFile = (file: CustomUploadFile) => {
        const link = document.createElement('a')
        link.href = file.thumbUrl || ''
        link.setAttribute('download', `${file?.name || 'image.png'}`)
        document.body.appendChild(link)
        link.click()
    }

    const deleteFile = (file: CustomUploadFile) => {
        const newFileList = fileList.filter((f: CustomUploadFile) => {
            return f.uid !== file.uid
        })

        setFileList(newFileList)
        setFileDetailsOpen(false)
    }

    const openFileDetails = (file: CustomUploadFile) => {
        setDetailFileSelected(file)
        handleFileDetailsOpen()
    }

    const selectCategory = (value: any) => {
        setSelectedCategory(value)
        handleSearchImages(value)
    }

    const handleSearchImages = (newCategory?: string) => {
        const categoryToUse = newCategory || selectedCategory
        let catId = mediaCategories.find(
            (cat: any) => cat.name.toLowerCase() === categoryToUse.toLowerCase()
        )

        if (catId) {
            setIsSearching(true)
            const isSearching = searchQuery.length > 2
            const queryParams = {
                catId: catId?.id,
                page: 1,
                size: mediaType === 'VIDEO' || mediaType === 'AUDIO' ? 10 : 25,
                ...(isSearching && { keyword: searchQuery }),
            }

            loadImages(
                queryParams.catId,
                queryParams.page,
                queryParams.keyword,
                queryParams.size
            )
                .then((data) => {
                    const fetchedFiles = compileImageData(data.content)
                    setFileList(fetchedFiles)
                    setCurrentPage(1)
                    setIsLastPage(data.last)
                })
                .catch((error) => {
                    console.error('Error fetching images:', error)
                })
                .finally(() => {
                    setIsSearching(false)
                })
        }
    }

    const handleCatDialogOpen = () => {
        setCatCreateOpen(true)
    }

    const handleCatDialogClose = () => {
        setCatCreateOpen(false)
    }

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <AddPhotoAlternateIcon
                sx={{ color: colors.greenAccent[500] }}
                fontSize="large"
            />
        </button>
    )

    return (
        <>
            <AlertDialog
                open={showDeletionAlert.enable}
                message={showDeletionAlert.message}
                onAcknowledge={() => {
                    setShowDeletionAlert({ enable: false, message: '' })
                }}
            />

            <Box display={'flex'} flexDirection={'column'} gap={2} height={700}>
                <Box display={'flex'} alignItems={'center'}>
                    <TextField
                        placeholder="Search"
                        id="outlined-start-adornment"
                        sx={{ width: '80%' }}
                        value={searchQuery}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                handleSearchImages(undefined)
                            }
                        }}
                    />

                    <ButtonExt
                        type="button"
                        value='Search'
                        style={{
                            marginLeft: '10px',
                            padding: '7px 25px'
                        }}
                        icon={<SearchIcon />}
                        onClickEvent={() => handleSearchImages(undefined)}
                    />

                    <FormControl sx={{ marginLeft: 2, width: '120ch' }}>
                        <Box display={'flex'} alignItems={'center'}>
                            <Box sx={{width: '80%'}}>
                                <AutocompleteExt
                                    name="category-id"
                                    multiSelection={false}
                                    label="Category"
                                    selectedValue={selectedCategory}
                                    onSelect={(value) => {
                                        selectCategory(value)
                                    }}
                                    options={mediaCategories.map((cat: any) => {
                                        return {
                                            value: cat.name,
                                            label: cat.name,
                                        }
                                    })}
                                    disableUnselectAll={true}
                                />
                            </Box>
                            <ButtonExt
                                type="button"
                                value='Create'
                                icon={<AddIcon />}
                                onClickEvent={handleCatDialogOpen}
                            />
                        </Box>
                    </FormControl>
                </Box>
                <Box width={880}>
                    {!isSearching ? (
                        <Upload
                            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                            accept={allowedFiles}
                            listType="picture-card"
                            multiple={true}
                            style={{
                                overflowY: 'scroll',
                                border: '1 solid white',
                            }}
                            itemRender={(
                                originNode: ReactElement,
                                file: CustomUploadFile,
                                fileList: object[]
                            ) => {
                                return (
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: '100px',
                                        }}
                                    >
                                        <Box>
                                            <Checkbox
                                                style={{
                                                    position: 'absolute',
                                                    zIndex: 1000,
                                                }}
                                                onChange={(e) =>
                                                    handleSelectFiles(e, file)
                                                }
                                            />
                                            <MoreVertIcon
                                                style={{
                                                    position: 'absolute',
                                                    cursor: 'pointer',
                                                    top: 9,
                                                    right: 5,
                                                    zIndex: 1000,
                                                    background: 'transparent',
                                                }}
                                                onClick={() => {
                                                    openFileDetails(file)
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            position="relative"
                                            height={100}
                                            style={{}}
                                        >
                                            {(mediaType === 'MEDIA' ||
                                                mediaType === 'BANNER') &&
                                                originNode}

                                            {mediaType === 'VIDEO' && (
                                                <video
                                                    style={{
                                                        marginTop: '5px',
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'contain',
                                                    }}
                                                    src={file.thumbUrl}
                                                    controls
                                                />
                                            )}
                                        </Box>
                                    </div>
                                )
                            }}
                            fileList={filteredFileList}
                            onChange={handleChange}
                            onRemove={(file: CustomUploadFile) =>
                                onRemoveClick(file)
                            }
                            showUploadList={{
                                showPreviewIcon: false,
                                showDownloadIcon: true,
                                showRemoveIcon: true,
                            }}
                            onDownload={(file: CustomUploadFile) =>
                                downloadFile(file)
                            }
                        >
                            {uploadButton}
                        </Upload>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '30px',
                            }}
                        >
                            <CircularProgress
                                sx={{
                                    color: colors.greenAccent[500],
                                }}
                                size={44}
                            />
                        </Box>
                    )}
                </Box>
                {selectedFiles?.length > 0 && (
                    <Box sx={{ textAlign: 'right' }}>
                        <ButtonExt
                            type="button"
                            value="Delete Selected"
                            onClickEvent={onRemoveBulkClick}
                        />
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingBottom: '20px',
                    }}
                >
                    {!isLastPage && filteredFileList?.length && !isSearching ? (
                        <ButtonExt
                            type="button"
                            style={{
                                padding: '5px 10px',
                                fontSize: '14px',
                                border: '1px solid',
                                borderRadius: '5px',
                            }}
                            value={isLoadingMore ? 'Loading...' : 'Load More'}
                            onClickEvent={fetchNextPage}
                        />
                    ) : (
                        ''
                    )}
                </Box>
                {!disabledAssign && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            paddingBottom: '20px',
                        }}
                    >
                        <ButtonExt
                            type="button"
                            value='ASSIGN'
                            disabled={selectedFiles.length === 0 || selectedFiles.length > 1}
                            onClickEvent={handleAssignMedia}
                        />
                    </Box>
                )}
            </Box>
            <Dialog
                open={deletePopupOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDeletePopupClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{}}>{'Are you sure ?'}</DialogTitle>
                <DialogActions>
                    <ButtonExt
                        type="button"
                        value='Delete'
                        onClickEvent={onConfirmDelete}
                    />
                    <ButtonExt
                        type="button"
                        value='Cancel'
                        onClickEvent={handleDeletePopupClose}
                    />
                </DialogActions>
            </Dialog>

            {detailFileSelected && (
                <FileDetailsModal
                    key={detailFileSelected?.uid}
                    userRole={user?.user?.role}
                    mediaType={mediaType}
                    file={detailFileSelected}
                    selectedCategoryId={mediaCategories.find((mc: any) => mc.name === selectedCategory)?.id}
                    mediaCategories={mediaCategories}
                    fileList={fileList}
                    setFileList={setFileList}
                    open={fileDetailsOpen}
                    downloadFile={downloadFile}
                    deleteFile={deleteFile}
                    onClose={handleFileDetailsClose}
                />
            )}

            <AddCategoryModal
                open={catCreateOpen}
                onClose={handleCatDialogClose}
                mediaType={mediaType}
                mediaCategories={mediaCategories}
            />
        </>
    )
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user }
}

export default connect(mapStateToProps)(MediaLibrary)
