import React, { useEffect, useMemo, useState } from 'react'
import { mediaQuery } from './MediaQuery'
import { v4 as uuidv4 } from 'uuid'
import store from '../redux/store'
import { LOAD_TABLE_DATA } from '../redux/actionType'
import { connect } from 'react-redux'
import { useAuthQueryWithQueryFunction } from '../extensions/UseAuthQuery'
import styled from 'styled-components'
import { Box, TextField } from '@mui/material'
import DataGrid, {
    DataGridColumnDescription,
    TablePaginationChangeProp,
} from './DataGrid'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import DownloadIcon from '@mui/icons-material/Download';
import RestoreIcon from '@mui/icons-material/Restore'
import { useNavigate } from 'react-router-dom'
import { ApiError } from '../interfaces/ErrorType'
import ErrorMessage from './ErrorMessage'
import ButtonExt from './ButtonExt'
import { useMutation } from 'react-query'
import jschardet from 'jschardet';
import iconv from 'iconv-lite';

// Create a reusable styled div component
const StyledSearchCriteriaDiv = styled.div<{ searchFilterCols: number }>`
    display: grid;
    grid-template-columns: ${({ searchFilterCols }) =>
        [...Array(searchFilterCols)].map(() => '1fr ')};
    grid-template-rows: auto;
    column-gap: 1em;
    row-gap: 1em;
    margin: 1rem;
    text-align: left;

    ${mediaQuery('tablet')`
      grid-template-columns: auto;
  `};
`

const DataGridFilter = (props: DataGridFilterProp) => {
    const {
        keyField,
        multiSelect = false,
        useQueryKey,
        reduxTableData,
        columns,
        onSearchPageUseQueryEvent,
        reportTitle,
        onDownloadEvent,
        searchFilterCols = 2,
        customSearchOptions,
        resetCustomSearchOptions,
        change,
        customSearchOptionsRenderer,
        customActionInputRenderer,
        createPageUrl,
        expandRow,
        selectedRow,
    } = props

    const navigate = useNavigate()
    // Filter specific table data based on useQueryKey
    const tableData = reduxTableData.find(
        (each: any) => each?.key === useQueryKey
    )?.data
    const sizePerPageList = [10, 25, 50, 100]
    const [keyword, setKeyword] = useState<string>('')
    const [reset, setReset] = useState<boolean>(false)
    const [download, setDownload] = useState<boolean>(false)
    const defaultCustomSearchOptions = useMemo(() => customSearchOptions, [])
    const defaultSearchOptions = {
        uuid: undefined,
        keyword: '',
        customSearchOptions: { ...defaultCustomSearchOptions },
        pageOptions: {
            page: 1,
            pageLimit: sizePerPageList[0],
        },
        sortOptions: undefined,
    }

    const [searchOptions, setSearchOptions] = useState<SearchOptionsProp>({
        ...defaultSearchOptions,
    })

    const { data, error, status, refetch } = useAuthQueryWithQueryFunction<
        any,
        ApiError,
        any
    >(
        [useQueryKey, searchOptions],
        () => onSearchPageUseQueryEvent(searchOptions),
        {
            refetchOnWindowFocus: false,
            retry: 1,
            enabled: false, // disable this query from automatically running
            onSuccess(data) {
                store.dispatch({
                    type: LOAD_TABLE_DATA,
                    payload: { key: useQueryKey, data: data?.data?.content },
                })
                if (reset) {
                    setReset(false)
                }
            },
            onError(error) {
                if (reset) {
                    setReset(false)
                }
            },
        }
    )

    const downloadMutation = useMutation<Blob, ApiError, SearchOptionsProp>(onDownloadEvent!);

    useEffect(() => {
        refetch()
    }, [searchOptions])

    useEffect(() => {
        onSearch()
    }, [change])

    const totalSize: number = data?.data?.totalElements
        ? data.data.totalElements
        : 0

    const handleTableChange = async (
        type: string,
        valueChange: TablePaginationChangeProp
    ) => {
        if (type === 'sort') {
            setSearchOptions({
                ...searchOptions,
                pageOptions: {
                    ...searchOptions.pageOptions,
                    page: 1,
                },
                sortOptions: {
                    ...searchOptions.sortOptions,
                    sortField: valueChange.sortField,
                    sortOrder: valueChange.sortOrder,
                },
            })
        }

        if (type === 'pagination') {
            setSearchOptions({
                ...searchOptions,
                uuid: uuidv4(),
                pageOptions: {
                    ...searchOptions.pageOptions,
                    page: valueChange.page === 0 ? 1 : valueChange.page,
                    pageLimit: valueChange.sizePerPage,
                },
            })
        }
    }

    const onSearch = async () => {
        setSearchOptions({
            ...searchOptions,
            uuid: uuidv4(),
            pageOptions: {
                ...searchOptions.pageOptions,
                page: 1,
            },
            keyword: keyword,
            customSearchOptions: customSearchOptions,
        })
    }

    const onDownload = async () => {
        if (!onDownloadEvent) {
            return;
        }

        try {
            setDownload(true);

            const data = await downloadMutation.mutateAsync(searchOptions);
            const rawDataAsString = String(data)

            // TODO: currently apply to windows-1252 only
            // Detect the actual encoding of the data
            const detectedEncoding = "windows-1252"
            // const encodingResult = jschardet.detect(rawDataAsString);
            // const detectedEncoding = encodingResult.encoding;

            // Convert the data based on detected encoding type
            const encodedData = iconv.encode(rawDataAsString, detectedEncoding);

            // Create a Blob from the encoded CSV data
            const file = new Blob([encodedData], { type: 'application/octet-stream; charset=' + detectedEncoding });

            // Create a URL for the Blob
            const fileURL = URL.createObjectURL(file);

            // Create a link element
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = `${reportTitle}-${new Date().toISOString().replace(/[-:.TZ]/g, '')}.csv`; // Set the desired file name and extension

            // Append the link to the document body and click it
            document.body.appendChild(link);
            link.click();

            // Clean up the created URL and remove the link element
            URL.revokeObjectURL(fileURL);
            document.body.removeChild(link);
        } finally {
            setDownload(false);
        }
    };

    const onReset = async () => {
        setReset(true)
        setKeyword('')
        setSearchOptions({
            ...defaultSearchOptions,
            uuid: uuidv4(),
        })

        if (resetCustomSearchOptions) {
            resetCustomSearchOptions({
                ...defaultSearchOptions.customSearchOptions,
            })
        }
    }

    return (
        <>
            <Box style={{ marginBottom: `2em` }}>
                {status === 'error' && <ErrorMessage error={error} />}
                {downloadMutation?.error && <ErrorMessage error={downloadMutation.error} />}
            </Box>
            <StyledSearchCriteriaDiv searchFilterCols={searchFilterCols}>
                <div
                    style={{
                        display: `grid`,
                        gridTemplateColumns: `auto`,
                        gridTemplateRows: `auto`,
                    }}
                >
                    <TextField
                        style={{ width: `auto`, height: `auto` }}
                        name="keyword"
                        value={keyword}
                        label="Search keyword..."
                        onChange={(e) => setKeyword(e.target.value)}
                    />
                </div>

                {customSearchOptionsRenderer}
            </StyledSearchCriteriaDiv>

            <StyledSearchCriteriaDiv searchFilterCols={1}>
                <div style={{ textAlign: `right` }}>
                    {createPageUrl && (
                        <ButtonExt
                            style={{
                                width: `auto`,
                                height: `auto`,
                                margin: `5px`,
                            }}
                            icon={<AddIcon />}
                            value="Create"
                            onClickEvent={() => navigate(createPageUrl)}
                        />
                    )}
                    <ButtonExt
                        style={{
                            width: `auto`,
                            height: `auto`,
                            margin: `5px`,
                        }}
                        icon={<SearchIcon />}
                        value={
                            status === 'loading' && !reset
                                ? 'Searching...'
                                : 'Search'
                        }
                        onClickEvent={() => onSearch()}
                    />
                    {(reportTitle && onDownloadEvent) && (
                        <ButtonExt
                            style={{
                                width: `auto`,
                                height: `auto`,
                                margin: `5px`,
                            }}
                            icon={<DownloadIcon />}
                            value={
                                download
                                    ? 'Downloading...'
                                    : 'Download'
                            }
                            onClickEvent={() => onDownload()}
                        />
                    )}
                    <ButtonExt
                        style={{
                            width: `auto`,
                            height: `auto`,
                            margin: `5px`,
                        }}
                        icon={<RestoreIcon />}
                        value={reset ? 'Resetting...' : 'Reset'}
                        onClickEvent={() => onReset()}
                    />
                </div>
            </StyledSearchCriteriaDiv>

            {customActionInputRenderer && (
                <Box margin="20px" gap="20px">
                    {customActionInputRenderer}
                </Box>
            )}

            <DataGrid
                keyField={keyField}
                multiSelect={multiSelect}
                columns={columns}
                data={tableData ? tableData : []}
                paginationFactory={{
                    page: searchOptions.pageOptions.page,
                    sizePerPage: searchOptions.pageOptions.pageLimit,
                    totalSize: totalSize,
                }}
                expandRow={expandRow}
                selectedRow={selectedRow}
                onTableChange={(type, valueChange) =>
                    handleTableChange(type, valueChange)
                }
            />
        </>
    )
}

/**
 * Contains the specific props type that can be passing
 */
interface DataGridFilterProp {
    keyField: string
    multiSelect?: boolean
    useQueryKey: string
    reduxTableData: any
    columns: DataGridColumnDescription[]
    onSearchPageUseQueryEvent: (searchOptions: SearchOptionsProp) => void // Handle event upon search page change
    reportTitle?: string
    onDownloadEvent?: (searchOptions: SearchOptionsProp) => Promise<any> // Handle event upon download change
    searchFilterCols?: number
    customSearchOptions?: any
    resetCustomSearchOptions?: (setCustomSearchOptions?: any) => void // Reset
    change?: string | undefined
    customSearchOptionsRenderer?: React.ReactElement
    customActionInputRenderer?: React.ReactElement
    createPageUrl?: string
    expandRow: (row: any) => any
    selectedRow?: (row: any[]) => void
}

export interface SearchOptionsProp {
    uuid: string | undefined
    keyword: string | undefined
    customSearchOptions: any
    pageOptions: PageOptionsProp
    sortOptions: SortOptionsProp | undefined
}

interface PageOptionsProp {
    page: number
    pageLimit: number
}

interface SortOptionsProp {
    sortField?: string | undefined
    sortOrder?: string | undefined
}

/**
 * Connect and retrieve the current table data through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { reduxTableData: state.tableData.data }
}

export default connect(mapStateToProps)(DataGridFilter)
