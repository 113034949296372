import React, { FunctionComponent, useEffect, useState } from 'react'
import { Alert, AlertTitle, Box, DialogContentText, IconButton, TextField, Tooltip } from '@mui/material'
import {
    AdminContentOwnershipTransferCustomBulkRequest,
    AdminContentSubmissionReviewCustomBulkRequest,
    ContentSubmission,
    ContentSubmissionComment,
    ContentSubmissionCommentRequest,
    ContentSubmissionRequest,
    ContentSubmissionWrapper,
    ContentType,
    Localization, Relationship,
} from '../../../../interfaces/ContentType'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { useMutation } from 'react-query'
import {
    bulkReassign,
    duplicateContent,
    loadCmsContentTypes,
    loadCmsLocalizations,
    saveComment,
    saveContent,
} from '../../../../actions/content'
import { ApiError } from '../../../../interfaces/ErrorType'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Header from '../../../../components/Header'
import ErrorMessage from '../../../../components/ErrorMessage'
import { Formik } from 'formik'
import { useAuthQueryWithQueryFunction } from '../../../../extensions/UseAuthQuery'
import AutocompleteExt from '../../../../components/Autocomplete'
import AccordionExt from '../../../../components/AccordionExt'
import TextareaAutosizeExt from '../../../../components/TextareaAutosize'
import ButtonExt from '../../../../components/ButtonExt'
import { v4 as uuidv4 } from 'uuid'
import { connect } from 'react-redux'
import { PromptType } from '../../../../interfaces/PromptType'
import { loadAvailablePromptTypes } from '../../../../actions/prompt'
import ReferenceItems from '../../../../components/ReferenceItems'
import {
    calculateDueByMonthAndSelectedDevelopmentStage,
    calculateDueByWeekAndSelectedDevelopmentStage,
    fetchDevelopmentStages,
} from '../../../../actions/developmentStage'
import LoadingOverlay from 'react-loading-overlay-ts'
// @ts-ignore
import AddMedia from '../../../../components/AddMedia'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
import { findContentAssignees, findMedicalAssignees } from '../../../../actions/user'
import { adminContentSubmissionStateOptions } from '../../../../share/ContentSubmissionConstant'
import AlertDialog from '../../../../components/AlertDialog'
import {
    contentAutoQaMedicalMerging,
    contentAutoQaMerging,
// @ts-ignore
} from '../../../../actions/contentAutoQa'
import ConfirmationDialog from '../../../../components/ConfirmationDialog'
import InfoIcon from '@mui/icons-material/Info'
import RichTextEditor from '../../../../components/NewRichTextEditor'
import TinyMCEContainer from '../../../../components/TinyMCEContainer'
import { loadCmsRelationships } from '../../../../actions/relationship'

const contentSubmissionSchema = yup.object().shape({
    title: yup.string().required('required'),
    subTitle: yup.string().required('required'),
    // FIX ME: let's investigate why this is not working
    // referenceItems: yup.array<ReferenceItem>().of(
    //     yup.object().shape({
    //         text: yup.string().required('Text is required'),
    //         urls: yup.array().min(1).required('URLs are required'),
    //     })
    // ).optional(),
    body: yup.string().required(),
    tinyMceBody: yup.string().required(),
    developmentStageId: yup.string().required(),
    weekOrMonth: yup.number().required(),
    // initialDue: yup.number().optional(),
    // dayOfWeekOrMonth: yup.number().optional(),
    due: yup.number().min(1).required(),
    // weekOrMonthTo: yup.number().optional(),
    // dueTo: yup.number().optional(),
    // delay: yup.number().min(1).optional(),
    locale: yup.string().required(),
    contentTypeIds: yup.array().min(1).required(),
    relationshipIds: yup.array().optional(),
    milestoneChecklist: yup.string().optional(),
    promptUsedIds: yup.array().optional(),
    promptUsedReference: yup.string().when('promptUsedIds', {
        is: (promptUsedIds: string[]) => promptUsedIds.length > 0,
        then: yup.string().required('required'),
    }),
    comment: yup.string().optional(),
})

const ContentSubmissionDetail: FunctionComponent<ContentSubmissionWrapper> = ({
                                                                                  user,
                                                                                  isNew,
                                                                                  wrapper,
                                                                                  callback,
                                                                                  impersonate,
                                                                              }) => {
    const navigate = useNavigate()
    const [contentSubmission, setContentSubmission] =
        useState<ContentSubmission>(wrapper!!)
    const [stateChange, setStateChange] = useState<string>(
        wrapper?.state ? wrapper.state : 'DRAFT',
    )
    // const [autoQA, setAutoQA] = useState<string>('')
    const [isSaved, setIsSaved] = useState<boolean>(false)
    const [isMedicalReassigned, setIsMedicalReassigned] = useState<boolean>(false)
    const [isContentOwnershipTransferred, setIsContentOwnershipTransferred] = useState<boolean>(false)
    const [contentSubmissionRequest, setContentSubmissionRequest] =
        useState<ContentSubmissionRequest>({
            id: wrapper?.id,
            title: wrapper?.title,
            subTitle: wrapper?.subTitle,
            referenceItems: wrapper?.referenceItems
                ? wrapper.referenceItems
                : [],
            body: wrapper?.body,
            tinyMceBody: wrapper?.tinyMceBody,
            developmentStageId: wrapper?.developmentStageId,
            weekOrMonth: wrapper?.weekOrMonth,
            initialDue: wrapper?.initialDue,
            dayOfWeekOrMonth: wrapper?.dayOfWeekOrMonth,
            due: wrapper?.due,
            weekOrMonthTo: wrapper?.weekOrMonthTo,
            dueTo: wrapper?.dueTo,
            delay: wrapper?.delay,
            locale: wrapper?.locale ? wrapper?.locale : 'en',
            contentTypeIds: wrapper
                ? wrapper.contentTypes.map((contentType) => contentType.id)
                : [],
            relationshipIds: wrapper && wrapper.relationships
                ? wrapper.relationships.map((relationship) => relationship.id)
                : [],
            milestoneChecklist: wrapper?.milestoneChecklist ? wrapper.milestoneChecklist.join("\n") : '',
            promptUsedIds:
                wrapper && wrapper.promptUsed
                    ? wrapper.promptUsed.map((each) => each.id!!)
                    : [],
            promptUsedReference: wrapper?.promptUsedReference,
            state: wrapper?.state,
            mediaKey: wrapper?.mediaKey,
            mediaPromptUsed: wrapper?.mediaPromptUsed,
            bannerKey: wrapper?.bannerKey,
            bannerPromptUsed: wrapper?.bannerPromptUsed,
            videoKey: wrapper?.videoKey,
            videoPromptUsed: wrapper?.videoPromptUsed,
        })
    const [isMilestone, setIsMilestone] = useState<boolean>(false);
    const [medicalAssignee, setMedicalAssignee] = useState<any>()
    const [contentAssignee, setContentAssignee] = useState<any>()
    const [medicalReassignRequest, setMedicalReassignRequest] =
        useState<AdminContentSubmissionReviewCustomBulkRequest>({
            contentSubmissionIds: contentSubmissionRequest.id
                ? [contentSubmissionRequest.id]
                : [],
            state: 'CONTENT_REVIEWED',
            assignee: undefined,
        })
    const [contentOwnershipTransferRequest, setContentOwnershipTransferRequest] =
        useState<AdminContentOwnershipTransferCustomBulkRequest>({
            contentSubmissionIds: contentSubmissionRequest.id
                ? [contentSubmissionRequest.id]
                : [],
            state: 'CONTENT_REVIEWED',
            assignee: undefined,
            transferOwnership: true,
        })
    const [save, setSave] = useState<string>('')
    // const [showAlert, setShowAlert] = useState({
    //     enable: false,
    //     message: '',
    // })
    // const [showConfirmation, setShowConfirmation] = useState({
    //     enable: false,
    //     message: '',
    //     onConfirm: (confirmed: boolean) => {}, // Corrected the syntax here
    // })

    const defaultError = {
        weekOrMonth: '',
        dayOfWeekOrMonth: '',
        weekOrMonthTo: '',
        dayOfMonthTo: '',
    }

    const [weekToDueErrors, setWeekToDueErrors] = useState(defaultError)
    const [monthToDueErrors, setMonthToDueErrors] = useState(defaultError)
    const [monthToErrors, setMonthToErrors] = useState(defaultError)
    const [weekToErrors, setWeekToErrors] = useState(defaultError)

    const [showConfirmation, setShowConfirmation] = useState({
        enable: false,
        message: '',
        state: '',
        values: {},
    });

    /**
     * Fetch content types
     * */
    const cmsContentTypeQuery = useAuthQueryWithQueryFunction<undefined,
        ApiError,
        ContentType[]>('cms-content-types', loadCmsContentTypes, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Fetch relationships
     */
    const cmsRelationshipQuery = useAuthQueryWithQueryFunction<undefined,
        ApiError,
        Relationship[]>('cms-relationships', loadCmsRelationships, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Fetch localizations
     * */
    const cmsLocalizationQuery = useAuthQueryWithQueryFunction<undefined,
        ApiError,
        Localization[]>('cms-localizations', loadCmsLocalizations, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Available prompt type query
     */
    const availablePromptTypeQuery = useAuthQueryWithQueryFunction<undefined,
        ApiError,
        PromptType[]>('promptType', loadAvailablePromptTypes, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Fetch development stage list
     */
    const developmentStageQuery = useAuthQueryWithQueryFunction<undefined,
        ApiError,
        any[]>('developmentStages', fetchDevelopmentStages, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Fetch medical assignees
     * */
    const medicalAssigneeQuery =
        user?.user?.role === 'ADMIN'
            ? // eslint-disable-next-line react-hooks/rules-of-hooks
            useAuthQueryWithQueryFunction<undefined, ApiError, any[]>(
                'medicalAssignees',
                findMedicalAssignees,
                {
                    refetchOnWindowFocus: false,
                    enabled: true,
                },
            )
            : { data: [] }

    /**
     * Fetch content assignees
     * */
    const contentAssigneeQuery =
        user?.user?.role === 'ADMIN'
            ? // eslint-disable-next-line react-hooks/rules-of-hooks
            useAuthQueryWithQueryFunction<undefined, ApiError, any[]>(
                'contentAssignees',
                findContentAssignees,
                {
                    refetchOnWindowFocus: false,
                    enabled: true,
                },
            )
            : { data: [] }

    /**
     * Calculate due by month and selected development stage
     */
    const calculateDueByMonthAndSelectedDevelopmentStageMutation = useMutation<any,
        ApiError,
        any>(calculateDueByMonthAndSelectedDevelopmentStage)

    /**
     * Calculate due by week and selected development stage
     */
    const calculateDueByWeekAndSelectedDevelopmentStageMutation = useMutation<any[],
        ApiError,
        any>(calculateDueByWeekAndSelectedDevelopmentStage)

    /**
     * Mutate content auto QA merging
     */
    const contentAutoQaMergingMutation = useMutation<any, ApiError, any>(
        contentAutoQaMerging,
    )

    // /**
    //  * Mutate content medical auto QA merging
    //  */
    // const contentMedicalAutoQaMergingMutation = useMutation<any, ApiError, any>(
    //     contentAutoQaMedicalMerging,
    // )

    const developmentStages = developmentStageQuery.data

    const availableDevelopmentStageOptions = developmentStages?.map(
        (developmentStage) => {
            return {
                value: developmentStage.id,
                label: developmentStage.type,
            }
        },
    )

    const developmentStage = developmentStages?.find(
        (developmentStage) =>
            developmentStage.id === contentSubmissionRequest.developmentStageId,
    )

    const [availableLocalizationsOptions, setAvailableLocalizationOptions] =
        useState<any[]>([])
    const [availableContentTypesOptions, setAvailableContentTypesOptions] =
        useState<any[]>([])
    const [availableRelationshipOptions, setAvailableRelationshipOptions] =
        useState<any[]>([])
    const [availablePromptOptions, setAvailablePromptOptions] = useState<any[]>(
        [],
    )
    const [availableMedicalUsersOptions, setAvailableMedicalUsersOptions] =
        useState<any[]>([])
    const [availableContentUsersOptions, setAvailableContentUsersOptions] =
        useState<any[]>([])

    useEffect(() => {
        if (cmsLocalizationQuery.data) {
            setAvailableLocalizationOptions(
                cmsLocalizationQuery.data.map((localization) => {
                    return {
                        value: localization.code,
                        label: localization.default
                            ? `${localization.name} (Default)`
                            : localization.name,
                    }
                }),
            )
        }
    }, [cmsLocalizationQuery.data])

    useEffect(() => {
        if (cmsContentTypeQuery.data) {
            const availableContentTypes = cmsContentTypeQuery.data.map((contentType) => {
                    return {
                        value: contentType.id,
                        label: contentType.type,
                    }
                })

            setAvailableContentTypesOptions(availableContentTypes)

            if (wrapper?.contentTypes && wrapper?.contentTypes!!.length > 0) {
                const isMilestone = wrapper.contentTypes.filter((each) => each.type.startsWith('Act')).length > 0
                setIsMilestone(isMilestone)
            }
        }
    }, [cmsContentTypeQuery.data])

    useEffect(() => {
        if (cmsRelationshipQuery.data) {
            setAvailableRelationshipOptions(
                cmsRelationshipQuery.data.map((relationship) => {
                    return {
                        value: relationship.id,
                        label: relationship.name,
                    }
                }),
            )
        }
    }, [cmsRelationshipQuery.data])

    useEffect(() => {
        if (availablePromptTypeQuery.data) {
            setAvailablePromptOptions(
                availablePromptTypeQuery.data.map((prompt) => {
                    return {
                        value: prompt.id,
                        label: prompt.title,
                    }
                }),
            )
        }
    }, [availablePromptTypeQuery.data])

    useEffect(() => {
        if (medicalAssigneeQuery.data) {
            setAvailableMedicalUsersOptions(
                medicalAssigneeQuery.data.map((user) => {
                    return {
                        value: user.username,
                        label: `${user.username} ${
                            user.displayName ? `(${user.displayName})` : ''
                        }`,
                    }
                }),
            )
        }
    }, [])

    useEffect(() => {
        if (contentAssigneeQuery.data) {
            setAvailableContentUsersOptions(
                contentAssigneeQuery.data.map((user) => {
                    return {
                        value: user.username,
                        label: `${user.username} ${
                            user.displayName ? `(${user.displayName})` : ''
                        }`,
                    }
                }),
            )
        }
    }, [])

    /**
     * Mutate content create/ update
     */
    const contentCreateOrUpdateMutation = useMutation<ContentSubmission,
        ApiError,
        ContentSubmissionRequest>(saveContent)

    /**
     * Mutate submit comment
     */
    const commentMutation = useMutation<ContentSubmissionComment[],
        ApiError,
        ContentSubmissionCommentRequest>(saveComment)

    if (contentCreateOrUpdateMutation.isSuccess && isNew) {
        if (user?.user?.role !== 'ADMIN') {
            navigate(`/content/submission`)
        } else {
            navigate(`/admin/content/review`)
        }
    }

    /**
     * Mutate reassign
     */
    const reassignMutation = useMutation<any,
        ApiError,
        AdminContentSubmissionReviewCustomBulkRequest>(bulkReassign)

    /**
     * Mutate duplicate content
     */
    const duplicateContentSubmissionMutation = useMutation<any, ApiError, string>(duplicateContent)

    const onMedicalReassign = (values: ContentSubmissionRequest) => {
        reassignMutation.mutate(medicalReassignRequest, {
            onSuccess: () => {
                onSaveAlertDefault(values, true, false)
                setIsMedicalReassigned(true)
                setTimeout(() => {
                    setIsMedicalReassigned(false)
                }, 3000)
            },
        })
    }

    /**
     * Duplicate content
     * @param values
     */
    const onDuplicateContent = (values: ContentSubmissionRequest) => {
        duplicateContentSubmissionMutation.mutate(values.id!!, {
            onSuccess: () => {
                if (callback) {
                    callback(uuidv4())
                }
            }
        })
    }

    const onContentOwnershipTransfer = (values: ContentSubmissionRequest) => {
        reassignMutation.mutate(contentOwnershipTransferRequest, {
            onSuccess: () => {
                onSaveAlertDefault(values, true, true)
                setIsContentOwnershipTransferred(true)
                setTimeout(() => {
                    setIsContentOwnershipTransferred(false)
                }, 3000)
            },
        })
    }

    // const onContentAutoQAMerging = (values: ContentSubmissionRequest) => {
    //     contentAutoQaMergingMutation.mutate(values, {
    //         onSuccess: (data) => {
    //             setAutoQA(data.content)
    //             setShowAlert({
    //                 enable: true,
    //                 message: `Auto QA is completed`,
    //             })
    //         },
    //     })
    // }
    //
    // const onContentMedicalAutoQAMerging = (
    //     values: ContentSubmissionRequest,
    // ) => {
    //     contentMedicalAutoQaMergingMutation.mutate(
    //         {
    //             ...values,
    //             previousBody: contentSubmission.previousBody,
    //         },
    //         {
    //             onSuccess: (data) => {
    //                 setAutoQA(data.content)
    //                 setShowAlert({
    //                     enable: true,
    //                     message: `Auto QA is completed`,
    //                 })
    //             },
    //         },
    //     )
    // }

    /**
     * Invoke an action to create/ update content
     * @param {*} e - event
     */
    const onSaveDefault = (values: ContentSubmissionRequest) => {
        onSave(values, stateChange)
    }

    const onSave = (values: ContentSubmissionRequest, newState: string) => {
        onSaveAlert(values, false, false, newState)
    }

    const onSaveAlertDefault = (values: ContentSubmissionRequest, hideAlert: boolean, transferOwnership: boolean) => {
        onSaveAlert(values, hideAlert, transferOwnership, stateChange)
    }

    const onSaveAlert = (values: ContentSubmissionRequest, hideAlert: boolean, transferOwnership: boolean, newState: string) => {
        if (values.comment && values.comment.length > 0) {
            addAComment({
                ...values,
                // Passing state change for update
                state: newState,
            })
        } else {
            if (
                isNew ||
                user?.user?.role === 'ADMIN' ||
                contentSubmissionRequest.state === 'DRAFT' ||
                contentSubmissionRequest.state === 'REQUEST_CHANGE' ||
                contentSubmissionRequest.state === 'CONTENT_REVIEWED'
            ) {
                saveSubmission({
                    ...values,
                    // Passing state change for update
                    state: newState,
                }, hideAlert, transferOwnership)
            }
        }
    }

    const saveSubmission = (values: ContentSubmissionRequest, hideAlert?: boolean, transferOwnership?: boolean) => {
        const milestoneChecklistAsString = values.milestoneChecklist as string | undefined
        const requestBody = {
            ...values,
            milestoneChecklist: milestoneChecklistAsString?.split('\n').filter((each) => each.trim().length > 0).map((each) => each.trim()),
        }
        contentCreateOrUpdateMutation.mutate(requestBody, {
            onSuccess: (data) => {
                setContentSubmission(data)
                setContentSubmissionRequest({
                    ...contentSubmissionRequest,
                    state: data.state,
                    id: data.id,
                })
                if (callback && !transferOwnership && (data.state === 'APPROVE' || data.state === 'REJECT' || data.state === 'REQUEST_APPROVAL' || (user?.user?.role === 'ADMIN' && data.state === 'REQUEST_CHANGE') || data.state === 'MEDICAL_REVIEWED')) {
                    // If the content has been reviewed, please refrain from refreshing the list, as the admin user will need to reassign the content to the medical user.
                    callback(uuidv4())
                } else {
                    if (!hideAlert) {
                        setIsSaved(true)
                        setTimeout(() => {
                            setIsSaved(false)
                        }, 3000)
                    }
                }

                setSave(uuidv4())
            },
        })
    }

    const addAComment = (values: ContentSubmissionRequest) => {
        commentMutation.mutate(values, {
            onSuccess: (data) => {
                if (
                    isNew ||
                    user?.user?.role === 'ADMIN' ||
                    contentSubmissionRequest.state === 'DRAFT' ||
                    contentSubmissionRequest.state === 'REQUEST_CHANGE' ||
                    contentSubmissionRequest.state === 'CONTENT_REVIEWED'
                ) {
                    saveSubmission(values)
                }

                setContentSubmission({
                    ...contentSubmission,
                    comments: data,
                })
                setContentSubmissionRequest({
                    ...contentSubmissionRequest,
                    state: values.state,
                    comment: '',
                })
                values.comment = ''
            },
        })
    }

    /**
     * Page containing audit log details
     */
    return (
        <Box m='20px'>
            {/*<AlertDialog*/}
            {/*    open={showAlert.enable}*/}
            {/*    message={showAlert.message}*/}
            {/*    onAcknowledge={() => {*/}
            {/*        setShowAlert({ enable: false, message: '' })*/}
            {/*    }}*/}
            {/*/>*/}

            {/*<ConfirmationDialog*/}
            {/*    open={showConfirmation.enable}*/}
            {/*    message={showConfirmation.message}*/}
            {/*    onConfirm={(confirmed) => {*/}
            {/*        if (confirmed) {*/}
            {/*            showConfirmation.onConfirm(true)*/}
            {/*        }*/}

            {/*        setShowConfirmation({*/}
            {/*            enable: false,*/}
            {/*            message: '',*/}
            {/*            onConfirm: (confirmed: boolean) => {*/}
            {/*            },*/}
            {/*        })*/}
            {/*    }}*/}
            {/*/>*/}

            <LoadingOverlay
                active={
                    calculateDueByMonthAndSelectedDevelopmentStageMutation.isLoading ||
                    calculateDueByWeekAndSelectedDevelopmentStageMutation.isLoading
                }
                spinner
                text='Loading...'
            >
                {isNew && (
                    <>
                        <Box
                            display='flex'
                            justifyContent='start'
                            mt='20px'
                            style={{ padding: `10px` }}
                        >
                            <IconButton
                                color='secondary'
                                onClick={() =>
                                    user?.user?.role !== 'ADMIN'
                                        ? navigate(`/content/submission`)
                                        : navigate(`/admin/content/review`)
                                }
                            >
                                <ArrowBackIcon /> Back
                            </IconButton>
                        </Box>

                        <Header title='Submit New Content' />
                    </>
                )}

                <Box style={{ marginBottom: `2em` }}>
                    {contentCreateOrUpdateMutation.isError && (
                        <ErrorMessage
                            error={contentCreateOrUpdateMutation.error}
                        />
                    )}

                    {contentAutoQaMergingMutation.isError && (
                        <ErrorMessage
                            error={contentAutoQaMergingMutation.error}
                        />
                    )}
                </Box>

                <Formik
                    onSubmit={onSaveDefault}
                    initialValues={contentSubmissionRequest}
                    validationSchema={contentSubmissionSchema}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display='grid'
                                gap='30px'
                                gridTemplateColumns='repeat(1, minmax(0,1fr))'
                            >
                                {values.id && (
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Id'
                                        value={values.id}
                                        name='id'
                                        disabled={true}
                                    />
                                )}

                                <TextField
                                    variant='filled'
                                    type='text'
                                    label='Title'
                                    onChange={handleChange}
                                    value={values.title}
                                    name='title'
                                    error={!!touched.title && !!errors.title}
                                    helperText={touched.title && errors.title}
                                    disabled={
                                        !isNew &&
                                        user?.user?.role !== 'ADMIN' &&
                                        !(
                                            values.state === 'DRAFT' ||
                                            values.state === 'REQUEST_CHANGE'
                                        )
                                    }
                                />

                                <TextField
                                    variant='filled'
                                    type='text'
                                    label='Subtitle'
                                    onChange={handleChange}
                                    value={values.subTitle}
                                    name='subTitle'
                                    error={
                                        !!touched.subTitle && !!errors.subTitle
                                    }
                                    helperText={
                                        touched.subTitle && errors.subTitle
                                    }
                                    disabled={
                                        !isNew &&
                                        user?.user?.role !== 'ADMIN' &&
                                        !(
                                            values.state === 'DRAFT' ||
                                            values.state === 'REQUEST_CHANGE'
                                        )
                                    }
                                />

                                <ReferenceItems
                                    values={values.referenceItems}
                                    label='Reference Items'
                                    onChangeEvent={(v) => {
                                        setContentSubmissionRequest({
                                            ...contentSubmissionRequest,
                                            referenceItems: v,
                                        })

                                        values.referenceItems = v
                                    }}
                                    error={
                                        !!touched.referenceItems &&
                                        !!errors.referenceItems
                                    }
                                    helperText={
                                        touched.referenceItems &&
                                        errors.referenceItems
                                    }
                                />
                            </Box>

                            <Box
                                display='grid'
                                gap='30px'
                                mt='30px'
                                gridTemplateColumns='repeat(2, minmax(0,1fr))'
                            >
                                <AutocompleteExt
                                    name='developmentStageId'
                                    multiSelection={false}
                                    label='Development Stage...'
                                    selectedValue={values.developmentStageId}
                                    onSelect={(value) => {
                                        setContentSubmissionRequest({
                                            ...contentSubmissionRequest,
                                            developmentStageId: value,
                                            weekOrMonth: undefined,
                                            initialDue: NaN,
                                            dayOfWeekOrMonth: NaN,
                                            due: NaN,
                                        })
                                        values.developmentStageId = value
                                        values.weekOrMonth = undefined
                                        values.initialDue = NaN
                                        values.dayOfWeekOrMonth = NaN
                                        values.due = NaN
                                    }}
                                    options={availableDevelopmentStageOptions}
                                    error={
                                        !!touched.developmentStageId &&
                                        !!errors.developmentStageId
                                    }
                                    helperText={
                                        touched.developmentStageId &&
                                        errors.developmentStageId
                                    }
                                />

                                {developmentStage?.supportedMonthConversion && (
                                    <>
                                        <Box display='grid' gridTemplateColumns='1fr 2em'>
                                            <TextField
                                                type='number'
                                                variant='filled'
                                                name='weekOrMonth'
                                                value={values.weekOrMonth}
                                                label={
                                                    developmentStage?.validMonthFrom
                                                        ? `Month (${developmentStage?.validMonthFrom}-${developmentStage?.validMonthTo})`
                                                        : 'Month...'
                                                }
                                                InputProps={{
                                                    inputProps: {
                                                        min: developmentStage?.validMonthFrom,
                                                        max: developmentStage?.validMonthTo,
                                                    },
                                                }}
                                                onBlur={(event) => {
                                                    if (
                                                        monthToDueErrors.weekOrMonth
                                                    ) {
                                                        return
                                                    }

                                                    calculateDueByMonthAndSelectedDevelopmentStageMutation.mutate(
                                                        {
                                                            month: values.weekOrMonth,
                                                            developmentStageId:
                                                            developmentStage?.id,
                                                        },
                                                        {
                                                            onSuccess: (data) => {
                                                                const initialDue =
                                                                    data - 1
                                                                setContentSubmissionRequest(
                                                                    {
                                                                        ...contentSubmissionRequest,
                                                                        due: data,
                                                                        delay: NaN,
                                                                        initialDue:
                                                                        initialDue,
                                                                        dayOfWeekOrMonth: 1,
                                                                        weekOrMonthTo: NaN,
                                                                        dueTo: NaN,
                                                                    },
                                                                )
                                                                values.due = data
                                                                values.delay = NaN
                                                                values.initialDue =
                                                                    initialDue
                                                                values.dayOfWeekOrMonth = 1
                                                                values.weekOrMonthTo = NaN
                                                                values.dueTo = NaN
                                                            },
                                                        },
                                                    )
                                                }}
                                                onChange={(event) => {
                                                    if (!event.target.value) {
                                                        setContentSubmissionRequest(
                                                            {
                                                                ...contentSubmissionRequest,
                                                                weekOrMonth: undefined,
                                                                dayOfWeekOrMonth:
                                                                NaN,
                                                                due: NaN,
                                                                delay: NaN,
                                                            },
                                                        )
                                                        values.weekOrMonth = undefined
                                                        values.dayOfWeekOrMonth =
                                                            NaN
                                                        values.due = NaN
                                                        values.delay = NaN
                                                    } else {
                                                        const month = parseInt(
                                                            event.target.value,
                                                        )

                                                        if (
                                                            (developmentStage?.validMonthFrom &&
                                                                month <
                                                                developmentStage?.validMonthFrom) ||
                                                            (developmentStage?.validMonthTo &&
                                                                month >
                                                                developmentStage?.validMonthTo)
                                                        ) {
                                                            setMonthToDueErrors({
                                                                ...monthToDueErrors,
                                                                weekOrMonth: `Month should be between ${developmentStage?.validMonthFrom} and ${developmentStage?.validMonthTo}`,
                                                            })
                                                        } else {
                                                            setMonthToDueErrors({
                                                                ...monthToDueErrors,
                                                                weekOrMonth: '',
                                                            })
                                                        }

                                                        setContentSubmissionRequest(
                                                            {
                                                                ...contentSubmissionRequest,
                                                                weekOrMonth: month,
                                                                initialDue: NaN,
                                                                dayOfWeekOrMonth:
                                                                NaN,
                                                            },
                                                        )

                                                        values.weekOrMonth = month
                                                        values.initialDue = NaN
                                                        values.dayOfWeekOrMonth =
                                                            NaN
                                                    }
                                                }}
                                                error={
                                                    (!!touched.weekOrMonth &&
                                                        !!errors.weekOrMonth) ||
                                                    !!monthToDueErrors.weekOrMonth
                                                }
                                                helperText={
                                                    (touched.weekOrMonth &&
                                                        errors.weekOrMonth) ||
                                                    monthToDueErrors.weekOrMonth
                                                }
                                            />
                                            <Tooltip
                                                title="When defining months, it's important to understand that 'Month 1' covers the period from Month 0 to Month 1, 'Month 2' spans from Month 1 to Month 2, and 'Month 12' includes the duration between Month 11 and Month 12."
                                                placement='top'>
                                                <InfoIcon />
                                            </Tooltip>
                                        </Box>

                                        <TextField
                                            type='number'
                                            variant='filled'
                                            name='dayOfWeekOrMonth'
                                            value={values.dayOfWeekOrMonth}
                                            label='Day of Month (1-28)...'
                                            InputProps={{
                                                inputProps: {
                                                    min: 1,
                                                    max: 28,
                                                },
                                            }}
                                            onChange={(event) => {
                                                const dayOfMonth = parseInt(
                                                    event.target.value,
                                                )

                                                let calculateDue = NaN
                                                if (
                                                    dayOfMonth < 1 ||
                                                    dayOfMonth > 28 ||
                                                    isNaN(dayOfMonth)
                                                ) {
                                                    setMonthToDueErrors({
                                                        ...monthToDueErrors,
                                                        dayOfWeekOrMonth: `Day of month should be between 1 and 28`,
                                                    })
                                                } else {
                                                    setMonthToDueErrors({
                                                        ...monthToDueErrors,
                                                        dayOfWeekOrMonth: '',
                                                    })

                                                    calculateDue =
                                                        values.initialDue!! +
                                                        dayOfMonth
                                                }

                                                setContentSubmissionRequest({
                                                    ...contentSubmissionRequest,
                                                    dayOfWeekOrMonth:
                                                    dayOfMonth,
                                                    due: calculateDue,
                                                })
                                                values.dayOfWeekOrMonth =
                                                    dayOfMonth
                                                values.due = calculateDue
                                            }}
                                            disabled={!values.weekOrMonth}
                                            error={
                                                (!!touched.dayOfWeekOrMonth &&
                                                    !!errors.dayOfWeekOrMonth) ||
                                                !!monthToDueErrors.dayOfWeekOrMonth
                                            }
                                            helperText={
                                                (touched.dayOfWeekOrMonth &&
                                                    errors.dayOfWeekOrMonth) ||
                                                monthToDueErrors.dayOfWeekOrMonth
                                            }
                                        />
                                    </>
                                )}

                                {!developmentStage?.supportedMonthConversion && (
                                    <>
                                        <TextField
                                            type='number'
                                            variant='filled'
                                            name='weekOrMonth'
                                            value={values.weekOrMonth}
                                            label={
                                                developmentStage?.weekFrom
                                                    ? `Week (${developmentStage?.weekFrom}-${developmentStage?.weekTo})`
                                                    : 'Week...'
                                            }
                                            InputProps={{
                                                inputProps: {
                                                    min: developmentStage?.weekFrom,
                                                    max: developmentStage?.weekTo,
                                                },
                                            }}
                                            onBlur={(event) => {
                                                if (
                                                    weekToDueErrors.weekOrMonth
                                                ) {
                                                    return
                                                }

                                                calculateDueByWeekAndSelectedDevelopmentStageMutation.mutate(
                                                    {
                                                        week: values.weekOrMonth,
                                                        developmentStageId:
                                                        developmentStage?.id,
                                                    },
                                                    {
                                                        onSuccess: (data) => {
                                                            const initialDue =
                                                                data[0] - 1
                                                            setContentSubmissionRequest(
                                                                {
                                                                    ...contentSubmissionRequest,
                                                                    due: data[0],
                                                                    delay: NaN,
                                                                    initialDue:
                                                                    initialDue,
                                                                    dayOfWeekOrMonth: 1,
                                                                    weekOrMonthTo: NaN,
                                                                    dueTo: NaN,
                                                                },
                                                            )
                                                            values.due = data[0]
                                                            values.delay = NaN
                                                            values.initialDue =
                                                                initialDue
                                                            values.dayOfWeekOrMonth = 1
                                                            values.weekOrMonthTo = NaN
                                                            values.dueTo = NaN
                                                        },
                                                    },
                                                )
                                            }}
                                            onChange={(event) => {
                                                if (!event.target.value) {
                                                    setContentSubmissionRequest(
                                                        {
                                                            ...contentSubmissionRequest,
                                                            weekOrMonth: undefined,
                                                            dayOfWeekOrMonth:
                                                            NaN,
                                                            due: NaN,
                                                            delay: NaN,
                                                        },
                                                    )
                                                    values.weekOrMonth = undefined
                                                    values.dayOfWeekOrMonth =
                                                        NaN
                                                    values.due = NaN
                                                    values.delay = NaN
                                                } else {
                                                    const week = parseInt(
                                                        event.target.value,
                                                    )

                                                    if (
                                                        week <
                                                        developmentStage?.weekFrom ||
                                                        week >
                                                        developmentStage?.weekTo
                                                    ) {
                                                        setWeekToDueErrors({
                                                            ...weekToDueErrors,
                                                            weekOrMonth: `Week should be between ${developmentStage?.weekFrom} and ${developmentStage?.weekTo}`,
                                                        })
                                                    } else {
                                                        setWeekToDueErrors({
                                                            ...weekToDueErrors,
                                                            weekOrMonth: '',
                                                        })
                                                    }

                                                    setContentSubmissionRequest(
                                                        {
                                                            ...contentSubmissionRequest,
                                                            weekOrMonth: week,
                                                            initialDue: NaN,
                                                            dayOfWeekOrMonth:
                                                            NaN,
                                                        },
                                                    )

                                                    values.weekOrMonth = week
                                                    values.initialDue = NaN
                                                    values.dayOfWeekOrMonth =
                                                        NaN
                                                }
                                            }}
                                            error={
                                                (!!touched.weekOrMonth &&
                                                    !!errors.weekOrMonth) ||
                                                !!weekToDueErrors.weekOrMonth
                                            }
                                            helperText={
                                                (touched.weekOrMonth &&
                                                    errors.weekOrMonth) ||
                                                weekToDueErrors.weekOrMonth
                                            }
                                        />

                                        <TextField
                                            type='number'
                                            variant='filled'
                                            name='dayOfWeekOrMonth'
                                            value={values.dayOfWeekOrMonth}
                                            label='Day of Week (1-7)...'
                                            InputProps={{
                                                inputProps: {
                                                    min: 1,
                                                    max: 7,
                                                },
                                            }}
                                            onChange={(event) => {
                                                const dayOfWeek = parseInt(
                                                    event.target.value,
                                                )

                                                let calculateDue = NaN

                                                if (
                                                    dayOfWeek < 1 ||
                                                    dayOfWeek > 7 ||
                                                    isNaN(dayOfWeek)
                                                ) {
                                                    setWeekToDueErrors({
                                                        ...weekToDueErrors,
                                                        dayOfWeekOrMonth: `Day of week should be between 1 and 7`,
                                                    })
                                                } else {
                                                    setWeekToDueErrors({
                                                        ...weekToDueErrors,
                                                        dayOfWeekOrMonth: '',
                                                    })

                                                    calculateDue =
                                                        values.initialDue!! +
                                                        dayOfWeek
                                                }

                                                setContentSubmissionRequest({
                                                    ...contentSubmissionRequest,
                                                    dayOfWeekOrMonth: dayOfWeek,
                                                    due: calculateDue,
                                                    delay: NaN,
                                                })
                                                values.dayOfWeekOrMonth =
                                                    dayOfWeek
                                                values.due = calculateDue
                                                values.delay = NaN
                                            }}
                                            disabled={!values.weekOrMonth}
                                            error={
                                                (!!touched.dayOfWeekOrMonth &&
                                                    !!errors.dayOfWeekOrMonth) ||
                                                !!weekToDueErrors.dayOfWeekOrMonth
                                            }
                                            helperText={
                                                (touched.dayOfWeekOrMonth &&
                                                    errors.dayOfWeekOrMonth) ||
                                                weekToDueErrors.dayOfWeekOrMonth
                                            }
                                        />
                                    </>
                                )}

                                <TextField
                                    variant='filled'
                                    type='number'
                                    label='Due'
                                    InputProps={{
                                        inputProps: {
                                            min: 1,
                                            max: developmentStage?.weekTo * 7,
                                        },
                                    }}
                                    onChange={handleChange}
                                    value={values.due}
                                    name='due'
                                    disabled={true}
                                />

                                {values.weekOrMonth && values.weekOrMonth < (developmentStage?.maxThreshold ? developmentStage?.maxThreshold : developmentStage?.weekTo) && !developmentStage?.supportedMonthConversion && (
                                    <>
                                        <TextField
                                            variant='filled'
                                            type='number'
                                            name='weekOrMonthTo'
                                            label={`Week To (${values.weekOrMonth + 1}-${developmentStage?.weekTo})`}
                                            value={values.weekOrMonthTo}
                                            InputProps={{
                                                inputProps: {
                                                    min: values.weekOrMonth + 1,
                                                    max: developmentStage?.weekTo,
                                                },
                                            }}
                                            onBlur={(event) => {
                                                if (
                                                    monthToDueErrors.weekOrMonthTo
                                                ) {
                                                    return
                                                }

                                                const weekUpTo = developmentStage?.weekTo
                                                if (values.weekOrMonthTo!! < values.weekOrMonth!! + 1 || values.weekOrMonthTo!! > weekUpTo) {
                                                    setMonthToErrors({
                                                        ...monthToErrors,
                                                        weekOrMonthTo: `Week should be between ${values.weekOrMonth!! + 1} and ${weekUpTo}`,
                                                    })
                                                    return
                                                }

                                                setMonthToErrors({
                                                    ...monthToErrors,
                                                    weekOrMonthTo: '',
                                                })

                                                calculateDueByWeekAndSelectedDevelopmentStageMutation.mutate(
                                                    {
                                                        week: values.weekOrMonthTo,
                                                        developmentStageId: developmentStage?.id,
                                                    },
                                                    {
                                                        onSuccess: (data) => {
                                                            const dueUpTo = data[0] + 6

                                                            setContentSubmissionRequest(
                                                                {
                                                                    ...contentSubmissionRequest,
                                                                    dueTo: dueUpTo,
                                                                },
                                                            )
                                                            values.dueTo = dueUpTo
                                                        },
                                                    },
                                                )
                                            }}
                                            onChange={(event) => {
                                                if (!event.target.value) {
                                                    setContentSubmissionRequest(
                                                        {
                                                            ...contentSubmissionRequest,
                                                            weekOrMonthTo: NaN,
                                                            dueTo: NaN,
                                                        },
                                                    )
                                                    values.weekOrMonthTo = NaN
                                                    values.dueTo = NaN
                                                } else {
                                                    const weekTo = parseInt(
                                                        event.target.value,
                                                    )

                                                    const validWeekUpTo = developmentStage?.weekTo
                                                    if (
                                                        (weekTo <
                                                            values.weekOrMonth!! + 1) ||
                                                        (developmentStage?.validMonthTo &&
                                                            weekTo > validWeekUpTo)
                                                    ) {
                                                        setMonthToErrors({
                                                            ...monthToErrors,
                                                            weekOrMonthTo: `Week should be between ${values.weekOrMonth!! + 1} and ${validWeekUpTo}`,
                                                        })
                                                    } else {
                                                        setMonthToErrors({
                                                            ...monthToErrors,
                                                            weekOrMonthTo: '',
                                                        })
                                                    }

                                                    setContentSubmissionRequest(
                                                        {
                                                            ...contentSubmissionRequest,
                                                            weekOrMonthTo: weekTo,
                                                            dueTo: NaN,
                                                        },
                                                    )

                                                    values.weekOrMonthTo = weekTo
                                                    values.dueTo = NaN
                                                }
                                            }}
                                            error={
                                                (!!touched.weekOrMonthTo &&
                                                    !!errors.weekOrMonthTo) ||
                                                !!monthToErrors.weekOrMonthTo
                                            }
                                            helperText={
                                                (touched.weekOrMonthTo &&
                                                    errors.weekOrMonthTo) ||
                                                monthToErrors.weekOrMonthTo
                                            }
                                        />

                                        <TextField
                                            variant='filled'
                                            type='number'
                                            label='Due To'
                                            onChange={handleChange}
                                            value={values.dueTo}
                                            name='dueTo'
                                            disabled={true}
                                        />
                                    </>
                                )}

                                {values.weekOrMonth && values.weekOrMonth < developmentStage?.validMonthTo && developmentStage?.supportedMonthConversion && (
                                    <>
                                        <TextField
                                            type='number'
                                            variant='filled'
                                            name='monthTo'
                                            value={values.weekOrMonthTo}
                                            label={
                                                developmentStage?.validMonthFrom
                                                    ? `Month To (${values.weekOrMonth + 1}-${developmentStage?.validMonthTo})`
                                                    : 'Month To...'
                                            }
                                            InputProps={{
                                                inputProps: {
                                                    min: values.weekOrMonth + 1,
                                                    max: developmentStage?.validMonthTo,
                                                },
                                            }}
                                            onBlur={(event) => {
                                                if (
                                                    monthToDueErrors.weekOrMonthTo
                                                ) {
                                                    return
                                                }

                                                if (values.weekOrMonthTo!! < values.weekOrMonth!! + 1 || values.weekOrMonthTo!! > developmentStage?.validMonthTo) {
                                                    setMonthToErrors({
                                                        ...monthToErrors,
                                                        weekOrMonthTo: `Month should be between ${values.weekOrMonth!! + 1} and ${developmentStage?.validMonthTo}`,
                                                    })
                                                    return
                                                }

                                                setMonthToErrors({
                                                    ...monthToErrors,
                                                    weekOrMonthTo: '',
                                                })

                                                calculateDueByMonthAndSelectedDevelopmentStageMutation.mutate(
                                                    {
                                                        month: values.weekOrMonthTo,
                                                        developmentStageId:
                                                        developmentStage?.id,
                                                    },
                                                    {
                                                        onSuccess: (data) => {
                                                            setContentSubmissionRequest(
                                                                {
                                                                    ...contentSubmissionRequest,
                                                                    dueTo: data + 28,
                                                                },
                                                            )
                                                            values.dueTo = data + 28
                                                        },
                                                    },
                                                )
                                            }}
                                            onChange={(event) => {
                                                if (!event.target.value) {
                                                    setContentSubmissionRequest(
                                                        {
                                                            ...contentSubmissionRequest,
                                                            weekOrMonthTo: NaN,
                                                            dueTo: NaN,
                                                        },
                                                    )
                                                    values.weekOrMonthTo = NaN
                                                    values.dueTo = NaN
                                                } else {
                                                    const monthTo = parseInt(
                                                        event.target.value,
                                                    )

                                                    if (
                                                        (developmentStage?.validMonthFrom &&
                                                            monthTo <
                                                            values.weekOrMonth!! + 1) ||
                                                        (developmentStage?.validMonthTo &&
                                                            monthTo >
                                                            developmentStage?.validMonthTo)
                                                    ) {
                                                        setMonthToErrors({
                                                            ...monthToErrors,
                                                            weekOrMonthTo: `Month should be between ${values.weekOrMonth!! + 1} and ${developmentStage?.validMonthTo}`,
                                                        })
                                                    } else {
                                                        setMonthToErrors({
                                                            ...monthToErrors,
                                                            weekOrMonthTo: '',
                                                        })
                                                    }

                                                    setContentSubmissionRequest(
                                                        {
                                                            ...contentSubmissionRequest,
                                                            weekOrMonthTo: monthTo,
                                                            dueTo: NaN,
                                                        },
                                                    )

                                                    values.weekOrMonthTo = monthTo
                                                    values.dueTo = NaN
                                                }
                                            }}
                                            error={
                                                (!!touched.weekOrMonthTo &&
                                                    !!errors.weekOrMonthTo) ||
                                                !!monthToErrors.weekOrMonthTo
                                            }
                                            helperText={
                                                (touched.weekOrMonthTo &&
                                                    errors.weekOrMonthTo) ||
                                                monthToErrors.weekOrMonthTo
                                            }
                                        />

                                        <TextField
                                            variant='filled'
                                            type='number'
                                            label='Due To'
                                            onChange={handleChange}
                                            value={values.dueTo}
                                            name='dueTo'
                                            disabled={true}
                                        />
                                    </>
                                )}

                                <TextField
                                    variant='filled'
                                    type='number'
                                    label='Delay'
                                    InputProps={{
                                        inputProps: {
                                            min: 1,
                                            max:
                                                (developmentStage?.maxThreshold -
                                                    developmentStage?.weekTo) *
                                                7,
                                        },
                                    }}
                                    onChange={handleChange}
                                    value={values.delay}
                                    name='delay'
                                    error={!!touched.delay && !!errors.delay}
                                    helperText={touched.delay && errors.delay}
                                    disabled={
                                        (!isNew &&
                                            user?.user?.role !== 'ADMIN' &&
                                            !(
                                                values.state === 'DRAFT' ||
                                                values.state ===
                                                'REQUEST_CHANGE'
                                            )) ||
                                        !(
                                            values.due ==
                                            developmentStage?.weekTo * 7
                                        )
                                    }
                                />

                                {/*Not allow to edit*/}
                                {isNew && (
                                    <AutocompleteExt
                                        name='locale'
                                        multiSelection={false}
                                        label='Locale'
                                        selectedValue={values.locale}
                                        options={availableLocalizationsOptions}
                                        onSelect={(v) => {
                                            setContentSubmissionRequest({
                                                ...contentSubmissionRequest,
                                                locale: v,
                                            })

                                            values.locale = v
                                        }}
                                        error={
                                            !!touched.locale && !!errors.locale
                                        }
                                        helperText={
                                            touched.locale && errors.locale
                                        }
                                        editable={
                                            isNew ||
                                            !(
                                                user?.user?.role !== 'ADMIN' &&
                                                !(
                                                    values.state === 'DRAFT' ||
                                                    values.state ===
                                                    'REQUEST_CHANGE'
                                                )
                                            )
                                        }
                                    />
                                )}

                                <AutocompleteExt
                                    name='contentTypeIds'
                                    multiSelection={true}
                                    label='Content Types'
                                    selectedValue={values.contentTypeIds}
                                    options={availableContentTypesOptions}
                                    onSelect={(v) => {
                                        let isMilestone = false
                                        if (v.length > 0) {
                                            isMilestone = v.length > 0 && availableContentTypesOptions.filter((each) => each.label.startsWith('Act') && v.includes(each.value)).length > 0
                                            setIsMilestone(isMilestone)
                                        } else {
                                            setIsMilestone(false)
                                        }

                                        setContentSubmissionRequest({
                                            ...contentSubmissionRequest,
                                            contentTypeIds: v,
                                            milestoneChecklist: isMilestone ? values.milestoneChecklist : '',
                                        })

                                        values.contentTypeIds = v
                                        if (!isMilestone) {
                                            values.milestoneChecklist = ''
                                        }
                                    }}
                                    error={
                                        !!touched.contentTypeIds &&
                                        !!errors.contentTypeIds
                                    }
                                    helperText={
                                        touched.contentTypeIds &&
                                        errors.contentTypeIds
                                    }
                                    editable={
                                        isNew ||
                                        !(
                                            user?.user?.role !== 'ADMIN' &&
                                            !(
                                                values.state === 'DRAFT' ||
                                                values.state ===
                                                'REQUEST_CHANGE'
                                            )
                                        )
                                    }
                                />

                                <AutocompleteExt
                                    name="relationshipIds"
                                    multiSelection={true}
                                    label='Relationships'
                                    selectedValue={values.relationshipIds}
                                    options={availableRelationshipOptions}
                                    onSelect={(v) => {
                                        setContentSubmissionRequest({
                                            ...contentSubmissionRequest,
                                            relationshipIds: v,
                                        })

                                        values.relationshipIds = v
                                    }}
                                    error={
                                        !!touched.relationshipIds &&
                                        !!errors.relationshipIds
                                    }
                                    helperText={
                                        touched.relationshipIds &&
                                        errors.relationshipIds
                                    }
                                    editable={
                                        isNew ||
                                        !(
                                            user?.user?.role !== 'ADMIN' &&
                                            !(
                                                values.state === 'DRAFT' ||
                                                values.state ===
                                                'REQUEST_CHANGE'
                                            )
                                        )
                                    }
                                />

                                <AutocompleteExt
                                    name='promptUsedIds'
                                    multiSelection={true}
                                    label='Prompt Used'
                                    selectedValue={values.promptUsedIds}
                                    options={availablePromptOptions}
                                    onSelect={(v) => {
                                        setContentSubmissionRequest({
                                            ...contentSubmissionRequest,
                                            promptUsedIds: v,
                                        })

                                        values.promptUsedIds = v
                                    }}
                                    error={
                                        !!touched.promptUsedIds &&
                                        !!errors.promptUsedIds
                                    }
                                    helperText={
                                        touched.promptUsedIds &&
                                        errors.promptUsedIds
                                    }
                                    editable={
                                        isNew ||
                                        !(
                                            user?.user?.role !== 'ADMIN' &&
                                            !(
                                                values.state === 'DRAFT' ||
                                                values.state ===
                                                'REQUEST_CHANGE'
                                            )
                                        )
                                    }
                                />

                                <TextField
                                    variant='filled'
                                    type='text'
                                    label='Prompt Used Reference'
                                    onChange={handleChange}
                                    value={values.promptUsedReference}
                                    name='promptUsedReference'
                                    error={
                                        !!touched.promptUsedReference &&
                                        !!errors.promptUsedReference
                                    }
                                    helperText={
                                        touched.promptUsedReference &&
                                        errors.promptUsedReference
                                    }
                                    disabled={
                                        !isNew &&
                                        user?.user?.role !== 'ADMIN' &&
                                        !(
                                            values.state === 'DRAFT' ||
                                            values.state === 'REQUEST_CHANGE'
                                        )
                                    }
                                />

                                {!isNew && (
                                    <>
                                        <TextField
                                            variant='filled'
                                            type='text'
                                            label='Assignee'
                                            value={
                                                contentSubmission &&
                                                contentSubmission.assignee
                                                    ? `${
                                                        contentSubmission
                                                            .assignee.username
                                                    } ${
                                                        contentSubmission
                                                            .assignee
                                                            .displayName
                                                            ? `(${contentSubmission.assignee.displayName})`
                                                            : ''
                                                    }`
                                                    : '-'
                                            }
                                            name='assignee'
                                            disabled={true}
                                        />

                                        <TextField
                                            variant='filled'
                                            type='text'
                                            label='Created By'
                                            value={
                                                contentSubmission &&
                                                contentSubmission.createdBy?.username
                                                    ? `${
                                                        contentSubmission
                                                            .createdBy
                                                            .username
                                                    } ${
                                                        contentSubmission
                                                            .createdBy
                                                            .displayName
                                                            ? `(${contentSubmission.createdBy.displayName})`
                                                            : ''
                                                    }`
                                                    : '-'
                                            }
                                            name='createdBy'
                                            disabled={true}
                                        />

                                        <TextField
                                            variant='filled'
                                            type='text'
                                            label='Created At'
                                            value={
                                                contentSubmission &&
                                                contentSubmission.createdAt
                                                    ? `${contentSubmission.createdAt}`
                                                    : '-'
                                            }
                                            name='createdAt'
                                            disabled={true}
                                        />

                                        <TextField
                                            variant='filled'
                                            type='text'
                                            label='Updated By'
                                            value={
                                                contentSubmission &&
                                                contentSubmission.updatedBy?.username
                                                    ? `${
                                                        contentSubmission
                                                            .updatedBy
                                                            .username
                                                    } ${
                                                        contentSubmission
                                                            .updatedBy
                                                            .displayName
                                                            ? `(${contentSubmission.updatedBy.displayName})`
                                                            : ''
                                                    }`
                                                    : '-'
                                            }
                                            name='updatedBy'
                                            disabled={true}
                                        />

                                        <TextField
                                            variant='filled'
                                            type='text'
                                            label='Updated At'
                                            value={
                                                contentSubmission &&
                                                contentSubmission.updatedAt &&
                                                contentSubmission.updatedBy?.username
                                                    ? `${contentSubmission.updatedAt}`
                                                    : '-'
                                            }
                                            name='updatedAt'
                                            disabled={true}
                                        />

                                        <TextField
                                            variant='filled'
                                            type='text'
                                            label='Content Reviewed By'
                                            value={
                                                contentSubmission &&
                                                contentSubmission.contentReviewdBy?.username
                                                    ? `${
                                                        contentSubmission
                                                            .contentReviewdBy
                                                            .username
                                                    } ${
                                                        contentSubmission
                                                            .contentReviewdBy
                                                            .displayName
                                                            ? `(${contentSubmission.contentReviewdBy.displayName})`
                                                            : ''
                                                    }`
                                                    : '-'
                                            }
                                            name='contentReviewedBy'
                                            disabled={true}
                                        />

                                        <TextField
                                            variant='filled'
                                            type='text'
                                            label='Medical Reviewed By'
                                            value={
                                                contentSubmission &&
                                                contentSubmission.medicalReviewdBy?.username
                                                    ? `${
                                                        contentSubmission
                                                            .medicalReviewdBy
                                                            .username
                                                    } ${
                                                        contentSubmission
                                                            .medicalReviewdBy
                                                            .displayName
                                                            ? `(${contentSubmission.medicalReviewdBy.displayName})`
                                                            : ''
                                                    }`
                                                    : '-'
                                            }
                                            name='medicalReviewedBy'
                                            disabled={true}
                                        />
                                    </>
                                )}
                            </Box>

                            <Box
                                display='grid'
                                gap='30px'
                                mt='30px'
                                gridTemplateColumns='repeat(1, minmax(0,1fr))'
                            >
                                <TinyMCEContainer
                                    contentId={values.id}
                                    htmlValue={values.tinyMceBody ? values.tinyMceBody : ''}
                                    onBlurEvent={handleBlur}
                                    onChangeEvent={(v: any) => {
                                        setContentSubmissionRequest({
                                            ...contentSubmissionRequest,
                                            body: v.markdownValue,
                                            tinyMceBody: v.htmlValue,
                                        })
                                        values.body = v.markdownValue
                                        values.tinyMceBody = v.htmlValue
                                    }}
                                    error={(!!touched.tinyMceBody && !!errors.tinyMceBody) || (!!touched.body && !!errors.body)}
                                    helperText={(touched.tinyMceBody && errors.tinyMceBody) || (touched.body && errors.body)}
                                    editable={
                                        isNew ||
                                        !(
                                            user?.user?.role !== 'ADMIN' &&
                                            !(
                                                values.state === 'DRAFT' ||
                                                values.state === 'REQUEST_CHANGE' ||
                                                values.state === 'CONTENT_REVIEWED'
                                            )
                                        )
                                    }
                                    onSaveKey={save}
                                />
                                {/*<RichTextEditor*/}
                                {/*    value={values.body ? values.body : ''}*/}
                                {/*    onChangeEvent={(v) => {*/}
                                {/*        setContentSubmission({*/}
                                {/*            ...contentSubmission,*/}
                                {/*            body: v,*/}
                                {/*        })*/}
                                {/*        setContentSubmissionRequest({*/}
                                {/*            ...contentSubmissionRequest,*/}
                                {/*            body: v,*/}
                                {/*        })*/}
                                {/*        values.body = v*/}
                                {/*    }}*/}
                                {/*    error={!!touched.body && !!errors.body}*/}
                                {/*    helperText={touched.body && errors.body}*/}
                                {/*    editable={*/}
                                {/*        isNew ||*/}
                                {/*        !(*/}
                                {/*            user?.user?.role !== 'ADMIN' &&*/}
                                {/*            !(*/}
                                {/*                values.state === 'DRAFT' ||*/}
                                {/*                values.state ===*/}
                                {/*                    'REQUEST_CHANGE' ||*/}
                                {/*                values.state ===*/}
                                {/*                    'CONTENT_REVIEWED'*/}
                                {/*            )*/}
                                {/*        )*/}
                                {/*    }*/}
                                {/*/>*/}

                                {/*{autoQA && (*/}
                                {/*    <ReactDiffViewer*/}
                                {/*        leftTitle={'Auto QA Changes'}*/}
                                {/*        oldValue={values.body}*/}
                                {/*        newValue={autoQA}*/}
                                {/*        splitView={true}*/}
                                {/*    />*/}
                                {/*)}*/}

                                {/*<Box textAlign="right">*/}
                                {/*    {values.state !== 'CONTENT_REVIEWED' &&*/}
                                {/*        values.state !== 'APPROVE' &&*/}
                                {/*        values.state !== 'REJECT' &&*/}
                                {/*        values.body && (*/}
                                {/*            <ButtonExt*/}
                                {/*                style={{ marginRight: '10px' }}*/}
                                {/*                type="button"*/}
                                {/*                value={*/}
                                {/*                    contentAutoQaMergingMutation.isLoading*/}
                                {/*                        ? 'Auto QA is in progress...'*/}
                                {/*                        : !autoQA*/}
                                {/*                        ? 'Auto QA'*/}
                                {/*                        : 'Re-Run Auto QA'*/}
                                {/*                }*/}
                                {/*                onClickEvent={() => {*/}
                                {/*                    if (*/}
                                {/*                        contentSubmission.state ===*/}
                                {/*                        'MEDICAL_REVIEWED'*/}
                                {/*                    ) {*/}
                                {/*                        onContentMedicalAutoQAMerging(*/}
                                {/*                            values*/}
                                {/*                        )*/}
                                {/*                    } else {*/}
                                {/*                        onContentAutoQAMerging(*/}
                                {/*                            values*/}
                                {/*                        )*/}
                                {/*                    }*/}
                                {/*                }}*/}
                                {/*            />*/}
                                {/*        )}*/}

                                {/*    {autoQA && (*/}
                                {/*        <>*/}
                                {/*            <ButtonExt*/}
                                {/*                style={{ marginRight: '10px' }}*/}
                                {/*                type="button"*/}
                                {/*                value={'Revert Changes'}*/}
                                {/*                onClickEvent={() => {*/}
                                {/*                    setAutoQA('')*/}
                                {/*                }}*/}
                                {/*            />*/}
                                {/*            <ButtonExt*/}
                                {/*                type="button"*/}
                                {/*                value={'Accept Changes'}*/}
                                {/*                onClickEvent={() => {*/}
                                {/*                    setShowConfirmation({*/}
                                {/*                        enable: true,*/}
                                {/*                        message:*/}
                                {/*                            'Are you sure you want to accept this changes?',*/}
                                {/*                        onConfirm: (*/}
                                {/*                            confirmed: boolean*/}
                                {/*                        ) => {*/}
                                {/*                            if (confirmed) {*/}
                                {/*                                setContentSubmission(*/}
                                {/*                                    {*/}
                                {/*                                        ...contentSubmission,*/}
                                {/*                                        body: autoQA,*/}
                                {/*                                    }*/}
                                {/*                                )*/}
                                {/*                                setContentSubmissionRequest(*/}
                                {/*                                    {*/}
                                {/*                                        ...contentSubmissionRequest,*/}
                                {/*                                        body: autoQA,*/}
                                {/*                                    }*/}
                                {/*                                )*/}
                                {/*                                values.body =*/}
                                {/*                                    autoQA*/}
                                {/*                                setAutoQA('')*/}
                                {/*                            }*/}
                                {/*                        },*/}
                                {/*                    })*/}
                                {/*                }}*/}
                                {/*            />*/}
                                {/*        </>*/}
                                {/*    )}*/}
                                {/*</Box>*/}

                                {/*{contentSubmission?.previousBody !== undefined && (*/}
                                {/*    <ReactDiffViewer*/}
                                {/*        leftTitle={'Changes'}*/}
                                {/*        oldValue={*/}
                                {/*            contentSubmission.previousBody*/}
                                {/*        }*/}
                                {/*        newValue={values.body}*/}
                                {/*        splitView={false}*/}
                                {/*        showDiffOnly={true}*/}
                                {/*        compareMethod={DiffMethod.SENTENCES}*/}
                                {/*    />*/}
                                {/*)}*/}

                                <TextareaAutosizeExt
                                    name="milestoneChecklist"
                                    label="Milestone Checklist"
                                    value={values.milestoneChecklist as string | undefined}
                                    onChange={(v) => {
                                        setContentSubmissionRequest({
                                            ...contentSubmissionRequest,
                                            milestoneChecklist: v,
                                        })

                                        values.milestoneChecklist = v
                                    }}
                                    minRows={10}
                                    maxRows={10}
                                    error={
                                        !!touched.milestoneChecklist &&
                                        !!errors.milestoneChecklist
                                    }
                                    helperText={
                                        touched.milestoneChecklist &&
                                        errors.milestoneChecklist
                                    }
                                    editable={isMilestone}
                                />

                                <AddMedia
                                    contentSubmissionRequestId={
                                        contentSubmissionRequest.id || ''
                                    }
                                    contentSubmissionRequest={
                                        values
                                    }
                                    onChangeEvent={(mediaType: string, key: string, promptUsed?: string) => {
                                        if (mediaType === 'MEDIA') {
                                            setContentSubmissionRequest({
                                                ...contentSubmissionRequest,
                                                mediaKey: key,
                                                mediaPromptUsed: promptUsed,
                                            })
                                            values.mediaKey = key
                                            values.mediaPromptUsed = promptUsed
                                        }

                                        if (mediaType === 'BANNER') {
                                            setContentSubmissionRequest({
                                                ...contentSubmissionRequest,
                                                bannerKey: key,
                                                bannerPromptUsed: promptUsed,
                                            })
                                            values.bannerKey = key
                                            values.bannerPromptUsed = promptUsed
                                        }

                                        if (mediaType === 'VIDEO') {
                                            setContentSubmissionRequest({
                                                ...contentSubmissionRequest,
                                                videoKey: key,
                                                videoPromptUsed: promptUsed,
                                            })
                                            values.videoKey = key
                                            values.videoPromptUsed = promptUsed
                                        }
                                    }}
                                    onClearMedia={(mediaType: string) => {
                                        if (mediaType === 'MEDIA') {
                                            setContentSubmissionRequest({
                                                ...contentSubmissionRequest,
                                                mediaKey: undefined,
                                                mediaPromptUsed: undefined,
                                            })
                                            values.mediaKey = undefined
                                            values.mediaPromptUsed = undefined
                                        }

                                        if (mediaType === 'BANNER') {
                                            setContentSubmissionRequest({
                                                ...contentSubmissionRequest,
                                                bannerKey: undefined,
                                                bannerPromptUsed: undefined,
                                            })
                                            values.bannerKey = undefined
                                            values.bannerPromptUsed = undefined
                                        }

                                        if (mediaType === 'VIDEO') {
                                            setContentSubmissionRequest({
                                                ...contentSubmissionRequest,
                                                videoKey: undefined,
                                                videoPromptUsed: undefined,
                                            })
                                            values.videoKey = undefined
                                            values.videoPromptUsed = undefined
                                        }
                                    }}
                                />

                                <TextareaAutosizeExt
                                    name="mediaPromptUsed"
                                    label="Media Prompt Used"
                                    value={values.mediaPromptUsed}
                                    onChange={(v) => {
                                        setContentSubmissionRequest({
                                            ...contentSubmissionRequest,
                                            mediaPromptUsed: v,
                                        })

                                        values.mediaPromptUsed = v
                                    }}
                                    minRows={10}
                                    maxRows={10}
                                    error={
                                        !!touched.mediaPromptUsed &&
                                        !!errors.mediaPromptUsed
                                    }
                                    helperText={
                                        touched.mediaPromptUsed &&
                                        errors.mediaPromptUsed
                                    }
                                    editable={
                                        isNew ||
                                        !(
                                            user?.user?.role !== 'ADMIN' &&
                                            !(
                                                values.state === 'DRAFT' ||
                                                values.state === 'REQUEST_CHANGE' ||
                                                values.state === 'CONTENT_REVIEWED'
                                            )
                                        )
                                    }
                                />

                                <TextareaAutosizeExt
                                    name="bannerPromptUsed"
                                    label="Banner Prompt Used"
                                    value={values.bannerPromptUsed}
                                    onChange={(v) => {
                                        setContentSubmissionRequest({
                                            ...contentSubmissionRequest,
                                            bannerPromptUsed: v,
                                        })

                                        values.bannerPromptUsed = v
                                    }}
                                    minRows={10}
                                    maxRows={10}
                                    error={
                                        !!touched.bannerPromptUsed &&
                                        !!errors.bannerPromptUsed
                                    }
                                    helperText={
                                        touched.bannerPromptUsed &&
                                        errors.bannerPromptUsed
                                    }
                                    editable={
                                        isNew ||
                                        !(
                                            user?.user?.role !== 'ADMIN' &&
                                            !(
                                                values.state === 'DRAFT' ||
                                                values.state === 'REQUEST_CHANGE' ||
                                                values.state === 'CONTENT_REVIEWED'
                                            )
                                        )
                                    }
                                />

                                <TextareaAutosizeExt
                                    name="videoPromptUsed"
                                    label="Video Prompt Used"
                                    value={values.videoPromptUsed}
                                    onChange={(v) => {
                                        setContentSubmissionRequest({
                                            ...contentSubmissionRequest,
                                            videoPromptUsed: v,
                                        })

                                        values.videoPromptUsed = v
                                    }}
                                    minRows={10}
                                    maxRows={10}
                                    error={
                                        !!touched.videoPromptUsed &&
                                        !!errors.videoPromptUsed
                                    }
                                    helperText={
                                        touched.videoPromptUsed &&
                                        errors.videoPromptUsed
                                    }
                                    editable={
                                        isNew ||
                                        !(
                                            user?.user?.role !== 'ADMIN' &&
                                            !(
                                                values.state === 'DRAFT' ||
                                                values.state === 'REQUEST_CHANGE' ||
                                                values.state === 'CONTENT_REVIEWED'
                                            )
                                        )
                                    }
                                />

                                <TextField
                                    variant="filled"
                                    type="text"
                                    label="Status"
                                    value={
                                        contentSubmission &&
                                        contentSubmission.state
                                            ? adminContentSubmissionStateOptions.find(
                                                  (each) =>
                                                      each.value ===
                                                      contentSubmission.state
                                              )?.label
                                            : 'Draft'
                                    }
                                    name="state"
                                    disabled={true}
                                />
                            </Box>

                            {!isNew && (
                                <Box
                                    display="grid"
                                    gap="10px"
                                    mt="30px"
                                    gridTemplateColumns="repeat(1, minmax(0,1fr))"
                                >
                                    <Header title="Comments" />

                                    {contentSubmission.comments.map(
                                        (contentSubmissionComment) => {
                                            return (
                                                <AccordionExt
                                                    title={`${contentSubmissionComment.username}: ${contentSubmissionComment.commentAt}`}
                                                    component={
                                                        <TextareaAutosizeExt
                                                            value={
                                                                contentSubmissionComment.comment
                                                            }
                                                            minRows={10}
                                                            maxRows={10}
                                                            editable={false}
                                                        />
                                                    }
                                                />
                                            )
                                        }
                                    )}

                                    <TextareaAutosizeExt
                                        name="comment"
                                        label="Comment"
                                        value={values.comment}
                                        onChangeEvent={handleChange}
                                        minRows={10}
                                        maxRows={10}
                                        error={
                                            !!touched.comment &&
                                            !!errors.comment
                                        }
                                        helperText={
                                            touched.comment && errors.comment
                                        }
                                    />

                                    {user?.user?.role === 'ADMIN' &&
                                        contentSubmission.state ===
                                        'CONTENT_REVIEWED' && (
                                            <Box>
                                                <Box style={{color: `red`, margin: `1em`}} display={'flex'} gap={1}>
                                                    Note: This content has been reviewed. Please select a new assignee to reassign the content.
                                                </Box>
                                                <AutocompleteExt
                                                    name='assignee'
                                                    multiSelection={false}
                                                    label='Medical Assignee'
                                                    selectedValue={
                                                        medicalAssignee
                                                    }
                                                    onSelect={(value) => {
                                                        setMedicalAssignee(
                                                            value,
                                                        )
                                                        setMedicalReassignRequest(
                                                            {
                                                                ...medicalReassignRequest,
                                                                assignee:
                                                                value,
                                                            },
                                                        )
                                                    }}
                                                    options={
                                                        availableMedicalUsersOptions
                                                    }
                                                    disableUnselectAll={
                                                        false
                                                    }
                                                />
                                            </Box>
                                        )}

                                    {user?.user?.role === 'ADMIN' &&
                                        contentSubmission.state !== 'CONTENT_REVIEWED' &&
                                        contentSubmission.state !== 'APPROVE' &&
                                        contentSubmission.state !== 'REJECT' && (
                                            <Box>
                                                <AutocompleteExt
                                                    name='assignee'
                                                    multiSelection={false}
                                                    label='Content Ownership Transfer'
                                                    selectedValue={
                                                        contentAssignee || contentSubmission.assignee
                                                    }
                                                    onSelect={(value) => {
                                                        setContentAssignee(
                                                            value,
                                                        )
                                                        setContentOwnershipTransferRequest(
                                                            {
                                                                ...contentOwnershipTransferRequest,
                                                                assignee: value,
                                                            },
                                                        )
                                                    }}
                                                    options={
                                                        availableContentUsersOptions
                                                    }
                                                    disableUnselectAll={
                                                        false
                                                    }
                                                />
                                            </Box>
                                        )}
                                </Box>
                            )}

                            <Box
                                display="flex"
                                justifyContent="end"
                                mt="20px"
                                gap="20px"
                            >
                                {isSaved && (
                                    <Alert severity="success">
                                        <DialogContentText>Saved</DialogContentText>
                                    </Alert>
                                )}

                                {isMedicalReassigned && (
                                    <Alert severity="success">
                                        <DialogContentText>Reassigned</DialogContentText>
                                    </Alert>
                                )}

                                {isContentOwnershipTransferred && (
                                    <Alert severity="success">
                                        <DialogContentText>Ownership Transferred</DialogContentText>
                                    </Alert>
                                )}

                                {((user?.user?.role !== 'ADMIN' || impersonate) &&
                                    contentSubmissionRequest.state !== 'APPROVE' &&
                                    contentSubmissionRequest.state !== 'REJECT') && (
                                    <>
                                        <ButtonExt
                                            type="submit"
                                            value={
                                                contentCreateOrUpdateMutation.isLoading
                                                    ? 'Saving'
                                                    : 'Save'
                                            }
                                            disabled={
                                                contentCreateOrUpdateMutation.isLoading ||
                                                contentCreateOrUpdateMutation.isLoading ||
                                                (!isNew &&
                                                    user?.user?.role !==
                                                        'ADMIN' &&
                                                    !(
                                                        contentSubmission.state ===
                                                            'DRAFT' ||
                                                        contentSubmission.state ===
                                                            'REQUEST_CHANGE'
                                                    ) &&
                                                    !values.comment) ||
                                                values.referenceItems?.find(
                                                    (each) =>
                                                        each.text.trim()
                                                            .length === 0 ||
                                                        each.urls.length === 0
                                                ) !== undefined
                                            }
                                        />

                                        {((isNew ||
                                            user?.user?.role !== 'ADMIN' ||
                                            impersonate) &&
                                            (contentSubmissionRequest.state ===
                                                'DRAFT' ||
                                            contentSubmissionRequest.state ===
                                                'REQUEST_CHANGE')) && (
                                            <ButtonExt
                                                type="submit"
                                                value={
                                                    contentCreateOrUpdateMutation.isLoading
                                                        ? 'Requesting'
                                                        : 'Request Content Review'
                                                }
                                                disabled={
                                                    contentCreateOrUpdateMutation.isLoading ||
                                                    contentCreateOrUpdateMutation.isLoading ||
                                                    values.referenceItems?.find(
                                                        (each) =>
                                                            each.text.trim()
                                                                .length === 0 ||
                                                            each.urls.length ===
                                                                0
                                                    ) !== undefined ||
                                                    !values.mediaKey
                                                }
                                                onClickEvent={(e) => {
                                                    setStateChange(
                                                        'REQUEST_APPROVAL'
                                                    )
                                                    handleSubmit(e)
                                                }}
                                            />
                                        )}
                                    </>
                                )}

                                {user?.user?.role === 'ADMIN' &&
                                    !impersonate &&
                                    contentSubmissionRequest.state !==
                                        'APPROVE' &&
                                    contentSubmissionRequest.state !==
                                        'REJECT' && (
                                        <ButtonExt
                                            type="submit"
                                            value={
                                                contentCreateOrUpdateMutation.isLoading
                                                    ? 'Saving'
                                                    : 'Save For Later'
                                            }
                                            disabled={
                                                contentCreateOrUpdateMutation.isLoading ||
                                                contentCreateOrUpdateMutation.isLoading ||
                                                values.referenceItems?.find(
                                                    (each) =>
                                                        each.text.trim()
                                                            .length === 0 ||
                                                        each.urls.length === 0
                                                ) !== undefined
                                            }
                                        />
                                    )}

                                {user?.user?.role === 'ADMIN' &&
                                    contentSubmissionRequest.state ===
                                        'REQUEST_APPROVAL' && (
                                        <>
                                            <ButtonExt
                                                type="submit"
                                                value={
                                                    contentCreateOrUpdateMutation.isLoading
                                                        ? 'Requesting'
                                                        : 'Content Reviewed'
                                                }
                                                disabled={
                                                    contentCreateOrUpdateMutation.isLoading ||
                                                    contentCreateOrUpdateMutation.isLoading ||
                                                    values.referenceItems?.find(
                                                        (each) =>
                                                            each.text.trim()
                                                                .length === 0 ||
                                                            each.urls.length ===
                                                                0
                                                    ) !== undefined
                                                }
                                                onClickEvent={(e) => {
                                                    setStateChange(
                                                        'CONTENT_REVIEWED'
                                                    )
                                                    handleSubmit(e)
                                                }}
                                            />

                                            <ButtonExt
                                                type="submit"
                                                value={
                                                    contentCreateOrUpdateMutation.isLoading
                                                        ? 'Requesting'
                                                        : 'Request Change'
                                                }
                                                disabled={
                                                    contentCreateOrUpdateMutation.isLoading ||
                                                    contentCreateOrUpdateMutation.isLoading
                                                }
                                                onClickEvent={(e) => {
                                                    setStateChange(
                                                        'REQUEST_CHANGE'
                                                    )
                                                    handleSubmit(e)
                                                }}
                                            />

                                            <ButtonExt
                                                type="submit"
                                                value={
                                                    contentCreateOrUpdateMutation.isLoading
                                                        ? 'Requesting'
                                                        : 'Reject'
                                                }
                                                disabled={
                                                    contentCreateOrUpdateMutation.isLoading ||
                                                    contentCreateOrUpdateMutation.isLoading ||
                                                    !values.comment
                                                }
                                                onClickEvent={(e) => {
                                                    setShowConfirmation({
                                                        enable: true,
                                                        message: 'Are you sure you want to reject this content?',
                                                        state: 'REJECT',
                                                        values: values,
                                                    })
                                                }}
                                            />
                                        </>
                                    )}

                                {user?.user?.role === 'ADMIN' &&
                                    (contentSubmissionRequest.state === 'CONTENT_REVIEWED' ||
                                        contentSubmissionRequest.state === 'MEDICAL_REVIEWED') && (
                                        <>
                                            <ButtonExt
                                                type="button"
                                                value={
                                                    contentCreateOrUpdateMutation.isLoading
                                                        ? 'Requesting'
                                                        : 'Request Change'
                                                }
                                                disabled={
                                                    contentCreateOrUpdateMutation.isLoading ||
                                                    contentCreateOrUpdateMutation.isLoading ||
                                                    medicalAssignee !== undefined
                                                }
                                                onClickEvent={(e) => {
                                                    setShowConfirmation({
                                                        enable: true,
                                                        message: 'Are you sure you want to proceed with this change? This action will reset all current content and medical review records, and the previous data will no longer be accessible.',
                                                        state: 'REQUEST_CHANGE',
                                                        values: values,
                                                    })
                                                }}
                                            />

                                            <ButtonExt
                                                type="submit"
                                                value={
                                                    contentCreateOrUpdateMutation.isLoading
                                                        ? 'Requesting'
                                                        : 'Reject'
                                                }
                                                disabled={
                                                    contentCreateOrUpdateMutation.isLoading ||
                                                    contentCreateOrUpdateMutation.isLoading ||
                                                    !values.comment
                                                }
                                                onClickEvent={(e) => {
                                                    setShowConfirmation({
                                                        enable: true,
                                                        message: 'Are you sure you want to reject this content?',
                                                        state: 'REJECT',
                                                        values: values,
                                                    })
                                                }}
                                            />
                                        </>
                                    )}

                                <ConfirmationDialog open={showConfirmation.enable} message={showConfirmation.message} onConfirm={(confirmed) => {
                                    if (confirmed) {
                                        switch (showConfirmation.state) {
                                            case 'REQUEST_CHANGE': {
                                                setStateChange('REQUEST_CHANGE')
                                                onSave(showConfirmation.values, 'REQUEST_CHANGE')
                                                break;
                                            }
                                            case 'REJECT': {
                                                setStateChange('REJECT')
                                                onSave(showConfirmation.values, 'REJECT')
                                                break;
                                            }
                                        }
                                    }

                                    setShowConfirmation({
                                        enable: false,
                                        state: '',
                                        message: '',
                                        values: {},
                                    })
                                }} />

                                {contentSubmissionRequest.state !== 'APPROVE' &&
                                    contentSubmissionRequest.state !== 'REJECT' && (
                                    <>
                                        {user?.user?.role === 'ADMIN' && (
                                            <ButtonExt
                                                type="button"
                                                value={
                                                    reassignMutation.isLoading
                                                        ? `${contentSubmissionRequest.state === 'CONTENT_REVIEWED' ? 'Reassigning' : 'Transferring'}}`
                                                        : `${contentSubmissionRequest.state === 'CONTENT_REVIEWED' ? 'Reassign' : 'Transfer Ownership'}`
                                                }
                                                disabled={
                                                    reassignMutation.isLoading ||
                                                    reassignMutation.isLoading ||
                                                    (!medicalAssignee && !contentAssignee)
                                                }
                                                onClickEvent={() => {
                                                    if (contentSubmissionRequest.state === 'CONTENT_REVIEWED') {
                                                        onMedicalReassign(values)
                                                    } else {
                                                        onContentOwnershipTransfer(values)
                                                    }
                                                }}
                                            />
                                        )}

                                        {contentSubmissionRequest.state === 'CONTENT_REVIEWED' &&
                                            user?.user?.username?.toLowerCase() ===
                                            contentSubmission.assignee?.username?.toLowerCase() && (
                                            <ButtonExt
                                                type="submit"
                                                value={
                                                    contentCreateOrUpdateMutation.isLoading
                                                        ? 'Requesting'
                                                        : 'Medical Reviewed'
                                                }
                                                disabled={
                                                    contentCreateOrUpdateMutation.isLoading ||
                                                    contentCreateOrUpdateMutation.isLoading
                                                }
                                                onClickEvent={(e) => {
                                                    setStateChange(
                                                        'MEDICAL_REVIEWED'
                                                    )
                                                    handleSubmit(e)
                                                }}
                                            />
                                        )}
                                    </>
                                )}

                                {user?.user?.role === 'ADMIN' &&
                                    contentSubmissionRequest.state ===
                                        'MEDICAL_REVIEWED' && (
                                        <>
                                            <ButtonExt
                                                type="submit"
                                                value={
                                                    contentCreateOrUpdateMutation.isLoading
                                                        ? 'Requesting'
                                                        : 'Approve'
                                                }
                                                disabled={
                                                    contentCreateOrUpdateMutation.isLoading ||
                                                    contentCreateOrUpdateMutation.isLoading ||
                                                    values.referenceItems?.find(
                                                        (each) =>
                                                            each.text.trim()
                                                                .length === 0 ||
                                                            each.urls.length ===
                                                                0
                                                    ) !== undefined
                                                }
                                                onClickEvent={(e) => {
                                                    setStateChange('APPROVE')
                                                    handleSubmit(e)
                                                }}
                                            />
                                        </>
                                    )}

                                {user?.user?.role === 'ADMIN' &&
                                    contentSubmissionRequest.state === 'APPROVE' && (
                                        <>
                                            <ButtonExt
                                                type="button"
                                                value={
                                                    duplicateContentSubmissionMutation.isLoading
                                                        ? 'Duplicating'
                                                        : 'Duplicate'
                                                }
                                                disabled={
                                                    duplicateContentSubmissionMutation.isLoading ||
                                                    duplicateContentSubmissionMutation.isLoading
                                                }
                                                onClickEvent={(e) => {
                                                    onDuplicateContent(values)
                                                }}
                                            />
                                            <ButtonExt
                                                type="button"
                                                value={
                                                    reassignMutation.isLoading
                                                        ? 'Transferring'
                                                        : 'Transfer Ownership'
                                                }
                                                disabled={
                                                    reassignMutation.isLoading ||
                                                    reassignMutation.isLoading ||
                                                    (!medicalAssignee && !contentAssignee)
                                                }
                                                onClickEvent={() => {
                                                    onContentOwnershipTransfer(values)
                                                }}
                                            />
                                        </>
                                    )}
                            </Box>
                        </form>
                    )}
                </Formik>
            </LoadingOverlay>
        </Box>
    )
}

/**
 * Connect and retrieve the current user through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user }
}

export default connect(mapStateToProps)(ContentSubmissionDetail)
