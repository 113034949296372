import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import Header from '../../../../components/Header'
import DataGridFilter, { SearchOptionsProp } from '../../../../components/DataGridFilter'
import {
    AdminContentSubmissionReviewCustomBulkRequest,
    AdminContentSubmissionReviewCustomFilterOptions,
    ContentSubmission, ContentType,
    Localization,
} from '../../../../interfaces/ContentType'
import ContentSubmissionDetail from '../detail'
import {
    bulkApproval,
    bulkReassign,
    fetchContentSubmissions, generateContentSubmissionReport, loadCmsContentTypes,
    loadCmsLocalizations,
} from '../../../../actions/content'
import SelectExt from '../../../../components/Select'
import {
    adminContentSubmissionStateOptions,
} from '../../../../share/ContentSubmissionConstant'
import AutocompleteExt from '../../../../components/Autocomplete'
import { useAuthQueryWithQueryFunction } from '../../../../extensions/UseAuthQuery'
import { ApiError } from '../../../../interfaces/ErrorType'
import { findContentAssignees, findMedicalAssignees, findUsers } from '../../../../actions/user'
import ButtonExt from '../../../../components/ButtonExt'
import { useMutation } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import ErrorMessage from '../../../../components/ErrorMessage'
import ConfirmationDialog from '../../../../components/ConfirmationDialog'
import { PromptType } from '../../../../interfaces/PromptType'
import { loadAvailablePromptTypes } from '../../../../actions/prompt'
import CheckboxExt from '../../../../components/Checkbox'
import { fetchDevelopmentStages } from '../../../../actions/developmentStage'
import { connect } from 'react-redux'
import { AuthToken } from '../../../../actions/auth'
import { loadCmsRelationships } from '../../../../actions/relationship'

function AdminContentReview(props: { user?: AuthToken | undefined }) {

    const { user } = props
    const [change, setChange] = useState<string>()
    const [enableBulkAction, setEnableBulkAction] = useState(true)
    const [customSearchOptions, setCustomSearchOptions] =
        useState<AdminContentSubmissionReviewCustomFilterOptions>({
            milestoneChecklist: false,
            state: ["REQUEST_APPROVAL", "CONTENT_REVIEWED", "MEDICAL_REVIEWED"],
            locale: undefined,
            username: undefined,
            promptUsedIds: [],
            saveForLater: false,
        })
    const [customActionInputBulkRequest, setCustomActionInputBulkRequest] =
        useState<AdminContentSubmissionReviewCustomBulkRequest>({
            contentSubmissionIds: [],
            state: undefined,
            assignee: undefined,
        })
    const [showConfirmation, setShowConfirmation] = useState({
        enable: false,
        state: '',
        bulkAction: '',
    });

    /**
     * Mutate bulk approval
     */
    const bulkApprovalMutation = useMutation<any, ApiError, AdminContentSubmissionReviewCustomBulkRequest>(
        bulkApproval,
    )

    /**
     * Mutate bulk reassign
     */
    const bulkReassignMutation = useMutation<any, ApiError, AdminContentSubmissionReviewCustomBulkRequest>(
        bulkReassign,
    )

    const expandRow = (row: ContentSubmission) => (
        <ContentSubmissionDetail isNew={false} wrapper={row} callback={setChange} impersonate={user?.user?.role === 'ADMIN' ? true : false} />
    )

    const resetCustomActionInputBulkRequest = () => {
        setCustomActionInputBulkRequest({
            contentSubmissionIds: [],
            state: undefined,
            assignee: undefined,
        })
    }

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        resetCustomActionInputBulkRequest()
        const data = fetchContentSubmissions(searchOptions)
        setEnableBulkAction(true)
        return data
    }

    const onDownloadEvent = (searchOptions: SearchOptionsProp) => {
        const data = generateContentSubmissionReport(searchOptions)
        return data
    }

    const onBulkReassign = () => {
        bulkReassignMutation.mutate(customActionInputBulkRequest, {
            onSuccess: () => {
                setChange(uuidv4())
            }
        })
    }

    const onBulkApproval = () => {
        bulkApprovalMutation.mutate(customActionInputBulkRequest, {
            onSuccess: () => {
                setChange(uuidv4())
            }
        })
    }

    /**
     * Fetch localizations
     * */
    const cmsLocalizationQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        Localization[]
        >('cms-localizations', loadCmsLocalizations, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Fetch content assignees
     * */
    const contentAssigneeQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('contentAssignees', findContentAssignees, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Fetch medical assignees
     * */
    const medicalAssigneeQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('medicalAssignees', findMedicalAssignees, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Available prompt type query
     */
    const availablePromptTypeQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        PromptType[]
        >('promptType', loadAvailablePromptTypes, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Fetch development stage list
     */
    const availableDevelopmentStageQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('developmentStages', fetchDevelopmentStages, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Fetch content types
     * */
    const cmsContentTypeQuery = useAuthQueryWithQueryFunction<undefined,
        ApiError,
        ContentType[]>('cms-content-types', loadCmsContentTypes, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Fetch relationships
     */
    const cmsRelationshipQuery = useAuthQueryWithQueryFunction<undefined,
        ApiError,
        any[]>('cms-relationships', loadCmsRelationships, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    const [availableContentUsersOptions, setAvailableContentUsersOptions] = useState<any[]>([])
    const [availableMedicalUsersOptions, setAvailableMedicalUsersOptions] = useState<any[]>([])
    const [availableLocalizationsOptions, setAvailableLocalizationOptions] = useState<any[]>([])
    const [availablePromptOptions, setAvailablePromptOptions] = useState<any[]>([])
    const [availableContentTypesOptions, setAvailableContentTypesOptions] = useState<any[]>([])
    const [availableRelationshipOptions, setAvailableRelationshipOptions] = useState<any[]>([])
    const [developmentStages, setDevelopmentStages] = useState<any[]>([])

    useEffect(() => {
        if (cmsLocalizationQuery.data) {
            setAvailableLocalizationOptions(cmsLocalizationQuery.data.map(localization => {
                return {
                    value: localization.code,
                    label: localization.default ? `${localization.name} (Default)` : localization.name,
                }
            }))
        }
    }, [cmsLocalizationQuery.data])

    useEffect(() => {
        if (contentAssigneeQuery.data) {
            setAvailableContentUsersOptions(contentAssigneeQuery.data.map(user => {
                return {
                    value: user.username,
                    label: `${user.username} ${user.displayName ? `(${user.displayName})` : ''}`,
                }
            }))
        }
    }, [contentAssigneeQuery.data])

    useEffect(() => {
        if (medicalAssigneeQuery.data) {
            setAvailableMedicalUsersOptions(medicalAssigneeQuery.data.map(user => {
                return {
                    value: user.username,
                    label: `${user.username} ${user.displayName ? `(${user.displayName})` : ''}`,
                }
            }))
        }
    }, [medicalAssigneeQuery.data])

    useEffect(() => {
        if (availablePromptTypeQuery.data) {
            setAvailablePromptOptions(availablePromptTypeQuery.data.map(prompt => {
                return {
                    value: prompt.id,
                    label: prompt.title,
                }
            }))
        }
    }, [availablePromptTypeQuery.data])

    useEffect(() => {
        if (availableDevelopmentStageQuery.data) {
            setDevelopmentStages(availableDevelopmentStageQuery.data)
        }
    }, [availableDevelopmentStageQuery.data])

    useEffect(() => {
        if (cmsContentTypeQuery.data) {
            setAvailableContentTypesOptions(cmsContentTypeQuery.data.map(contentType => {
                return {
                    value: contentType.id,
                    label: contentType.type,
                }
            }))
        }
    }, [cmsContentTypeQuery.data])

    useEffect(() => {
        if (cmsRelationshipQuery.data) {
            setAvailableRelationshipOptions(cmsRelationshipQuery.data.map(relationship => {
                return {
                    value: relationship.id,
                    label: relationship.name,
                }
            }))
        }
    }, [cmsRelationshipQuery.data])

    const customSearchOptionsRenderer = () => (
        <>
            <SelectExt
                name="state"
                multiSelection={true}
                label="Status"
                selectedValue={customSearchOptions.state}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        state: value,
                    })
                    setEnableBulkAction(false)
                    resetCustomActionInputBulkRequest()
                }}
                options={adminContentSubmissionStateOptions}
            />

            <CheckboxExt
                name="saveForLater"
                label="Filtered by Save for Later"
                value={customSearchOptions.saveForLater}
                onChange={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        saveForLater: value,
                    })
                }}
            />

            <AutocompleteExt
                name='contentTypeIds'
                multiSelection={false}
                label='Content Type'
                selectedValue={customSearchOptions.contentTypeId}
                options={availableContentTypesOptions}
                onSelect={(v) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        contentTypeId: v,
                    })
                }}
            />

            <CheckboxExt
                name="milestoneChecklist"
                label="Milestone Checklist Only"
                value={customSearchOptions.milestoneChecklist}
                onChange={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        milestoneChecklist: value,
                    })
                }}
            />

            <AutocompleteExt
                name='relationshipId'
                multiSelection={false}
                label='Relationship'
                selectedValue={customSearchOptions.relationshipId}
                options={availableRelationshipOptions}
                onSelect={(v) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        relationshipId: v,
                    })
                }}
            />

            <AutocompleteExt
                name="locale"
                multiSelection={false}
                label="Locale"
                selectedValue={customSearchOptions.locale}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        locale: value,
                    })
                }}
                options={availableLocalizationsOptions}
            />

            <AutocompleteExt
                name='contentAssignee'
                multiSelection={false}
                label='Content User'
                selectedValue={customSearchOptions.username}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        username: value ? value : '',
                    })
                }}
                options={availableContentUsersOptions}
                disableUnselectAll={false}
            />

            <AutocompleteExt
                name='medicalAssignee'
                multiSelection={false}
                label='Medical User'
                selectedValue={customSearchOptions.username}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        username: value ? value : '',
                    })
                }}
                options={availableMedicalUsersOptions}
                disableUnselectAll={false}
            />

            <AutocompleteExt
                name="promptUsedIds"
                multiSelection={true}
                label="Prompt Used"
                selectedValue={customSearchOptions.promptUsedIds}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        promptUsedIds: value,
                    })
                }}
                options={availablePromptOptions}
            />
        </>
    )

    const customActionInputRenderer = () => (
        <>
            <Box style={{ marginBottom: `2em` }}>
                {bulkReassignMutation.isError && (
                    <ErrorMessage error={bulkReassignMutation.error} />
                )}

                {bulkApprovalMutation.isError && (
                    <ErrorMessage error={bulkApprovalMutation.error} />
                )}
            </Box>

            <Header title='Bulk Request' titleVariant="h3" subtitle={`${customActionInputBulkRequest.contentSubmissionIds?.length} ${customActionInputBulkRequest.contentSubmissionIds && customActionInputBulkRequest.contentSubmissionIds.length <= 1 ? 'entry selected' : 'entries selected'} `}  />

            {enableBulkAction && (
                <>
                    {customSearchOptions.state && customSearchOptions.state.length >= 1 && (!customSearchOptions.state.includes("APPROVE") && !customSearchOptions.state.includes("REJECT")) && (
                        <Box display="grid" gridTemplateColumns="1fr 1fr" mt="20px">
                            <AutocompleteExt
                                name='assignee'
                                multiSelection={false}
                                label='Content Assignee'
                                selectedValue={customActionInputBulkRequest.assignee}
                                onSelect={(value) => {
                                    setCustomActionInputBulkRequest({
                                        ...customActionInputBulkRequest,
                                        assignee: value,
                                        state: undefined,
                                    })
                                }}
                                options={availableContentUsersOptions}
                                disableUnselectAll={false}
                                editable={customActionInputBulkRequest.contentSubmissionIds && customActionInputBulkRequest.contentSubmissionIds.length > 0}
                            />
                            <Box>
                                <ButtonExt
                                    style={{
                                        width: `auto`,
                                        height: `auto`,
                                        margin: `5px`,
                                    }}
                                    value={'Reassign'}
                                    onClickEvent={() => {
                                        setShowConfirmation({
                                            enable: true,
                                            state: '',
                                            bulkAction: 'REASSIGN',
                                        })
                                    }}
                                    disabled={!customActionInputBulkRequest.contentSubmissionIds || customActionInputBulkRequest.contentSubmissionIds.length === 0 || customActionInputBulkRequest.assignee === undefined}
                                />
                            </Box>
                        </Box>
                    )}

                    {customSearchOptions.state?.length === 1 && customSearchOptions.state.includes("REQUEST_APPROVAL") && (
                        <Box mt="20px">
                            <ButtonExt
                                style={{
                                    width: `auto`,
                                    height: `auto`,
                                    margin: `5px`,
                                }}
                                value={'Reject'}
                                onClickEvent={() => {
                                    setShowConfirmation({
                                        enable: true,
                                        state: 'REJECT',
                                        bulkAction: 'APPROVAL'
                                    })
                                }}
                                disabled={!customActionInputBulkRequest.contentSubmissionIds || customActionInputBulkRequest.contentSubmissionIds.length === 0}
                            />

                            <ButtonExt
                                style={{
                                    width: `auto`,
                                    height: `auto`,
                                    margin: `5px`,
                                }}
                                value={'Request Change'}
                                onClickEvent={() => {
                                    setShowConfirmation({
                                        enable: true,
                                        state: 'REQUEST_CHANGE',
                                        bulkAction: 'APPROVAL'
                                    })
                                }}
                                disabled={!customActionInputBulkRequest.contentSubmissionIds || customActionInputBulkRequest.contentSubmissionIds.length === 0}
                            />
                        </Box>
                    )}

                    <ConfirmationDialog open={showConfirmation.enable} message='Are you sure you want to proceed?' onConfirm={(confirmed) => {
                        if (confirmed) {
                            switch (showConfirmation.bulkAction) {
                                case 'APPROVAL': {
                                    customActionInputBulkRequest.state = showConfirmation.state
                                    onBulkApproval()
                                    break;
                                }
                                case 'REASSIGN': {
                                    onBulkReassign()
                                    break;
                                }
                            }
                        }

                        setShowConfirmation({
                            enable: false,
                            state: '',
                            bulkAction: '',
                        })
                    }} />
                </>
            )}
        </>
    )

    const columns = [
        {
            dataField: 'title',
            text: 'Title',
            sort: true,
        },
        {
            dataField: 'createdAt',
            text: 'Created At',
            sort: true,
        },
        {
            dataField: 'updatedAt',
            text: 'Last Updated At',
            sort: true,
        },
        {
            dataField: 'assignee',
            text: 'Assignee',
            sort: false,
            converter: (value: any) => {
                return value ? `${value.username} ${value.displayName ? `(${value.displayName})` : ''}` : value;
            }
        },
        {
            dataField: 'developmentStageId',
            text: 'Development Stage',
            converter: (value: any) => {
                const converted = developmentStages.find( each => each.id === value);
                return converted ? converted.type : value;
            },
        },
        {
            dataField: 'weekOrMonth',
            text: 'Week/ Month',
            sort: true,
        },
        {
            dataField: 'weekOrMonthTo',
            text: 'Week/ Month To',
            sort: true,
        },
        {
            dataField: 'delay',
            text: 'Delay',
            sort: false,
        },
        {
            dataField: 'contentTypes',
            text: 'Content Type',
            converter: (value: any) => {
                const converted = availableContentTypesOptions.find( each => each.value === value[0].id);
                return converted ? converted.label : value;
            },
        },
        {
            dataField: 'state',
            text: 'Status',
            converter: (value: any) => {
                const converted = adminContentSubmissionStateOptions.find( each => each.value === value);
                return converted ? converted.label : value;
            },
            sort: true,
        },
    ]

    return (
        <Box m='20px'>
            <Header title='Content Review' />

            <DataGridFilter
                keyField='id'
                multiSelect={enableBulkAction && ((customSearchOptions.state?.length === 1 && customSearchOptions.state.includes("REQUEST_APPROVAL")) || (customSearchOptions.state && customSearchOptions.state.length >= 1 && (!customSearchOptions.state.includes("APPROVE") && !customSearchOptions.state.includes("REJECT"))))}
                useQueryKey={`admin-content-submission-review`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                reportTitle={'Content-Review'}
                onDownloadEvent={onDownloadEvent}
                customSearchOptions={customSearchOptions}
                resetCustomSearchOptions={setCustomSearchOptions}
                change={change}
                customSearchOptionsRenderer={customSearchOptionsRenderer()}
                customActionInputRenderer={customActionInputRenderer()}
                searchFilterCols={2}
                createPageUrl={"/impersonate/content/submission/create"}
                expandRow={expandRow}
                selectedRow={(rows: any) => setCustomActionInputBulkRequest({
                    ...customActionInputBulkRequest,
                    contentSubmissionIds: rows.map((row: any) => row.id)
                })}
            />

        </Box>
    )
}

/**
 * Connect and retrieve the current user through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user }
}

export default connect(mapStateToProps)(AdminContentReview)